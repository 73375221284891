import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  AddressBookEntryDTO,
  AddressBookRequestLiteDTO,
  AddressBookServiceProxy,
  NetworkType
} from '../../service-proxies/service-proxies';
import { ComponentBase } from '../../shared/component-base';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { Router } from '@angular/router';
import { Web3Service } from '../../shared/web3-service';
import { SolanaWeb3Service } from 'src/internal/services/solana.service';
import { catchError, filter, switchMap, switchMapTo } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

@Component({
  templateUrl: './dlg-address-book.component.html',
})

export class DlgAddressBookComponent extends ComponentBase implements OnInit {
  private readonly evmNetworks: Array<number> = [NetworkType.BSC, NetworkType.BSCTest,
  NetworkType.Ethereum, NetworkType.Rinkeby, NetworkType.Gorli, NetworkType.Kovan,
  NetworkType.Polygon, NetworkType.PolygonTest,
  NetworkType.Heco, NetworkType.HecoTest];

  constructor(
    public dialogRef: MatDialogRef<DlgAddressBookComponent>,
    private userSessionProvider: UserSessionProvider,
    private web3Service: Web3Service,
    private router: Router,
    private addressBookServiceProxy: AddressBookServiceProxy,
    private readonly solanaService: SolanaWeb3Service
  ) {
    super();
  }
  @Input() actionAddressMode: string;
  @Input() dto: AddressBookEntryDTO;
  waiting: boolean;
  emissionAddress: string;
  async ngOnInit(): Promise<void> {
    console.log('linkedWallet');
    if (this.userSessionProvider.authToken) {
      //this.eventLogin(this.userSessionProvider.linkedWallet);
      this.emissionAddress = this.dto.emissionAddress;
    }
    else {
      console.error('Not Auth. redirect to login page');
      this.router.navigate(["/login"]);
    }
  }

  saveAddressClick(): void {
    this.waiting = true;

    of(this.isValidAddress(this.dto.networkId, this.emissionAddress)).pipe(
      filter((isNetworkAddress: boolean) => {
        if (!isNetworkAddress) {
          this.showWarningModal("Error address is not valid");
        }
        return isNetworkAddress;
      }),
      switchMapTo(this.addressBookServiceProxy.isAddressBookEntryExists(this.emissionAddress, this.dto.networkId)),
      filter(isExist => {
        if (isExist) {
          this.showWarningModal("Error this address already used");
        }
        return !isExist;
      }),
      switchMap(() => {
        const data: AddressBookRequestLiteDTO = new AddressBookRequestLiteDTO();
        data.networkId = this.dto.networkId;
        data.emissionAddress = this.emissionAddress;
        data.userWallet = this.dto.userWallet;
        return this.addressBookServiceProxy.addChangeRequest(data);
      }),
      catchError((error: any) => {
        this.waiting = false;
        this.processServiceError(error);
        return EMPTY;
      })
    ).subscribe(() => {
      this.waiting = false;
      this.showInfoModal("Saved");
      location.reload();
    });
  }

  processServiceError(error: any) {
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    }
    else {
      console.error(error);
      this.showErrorModal(JSON.parse(error.response).message);
    }
  }

  navigateToLogin(): void {
    this.router.navigate(["/login"]);
  }

  private isValidAddress(networkId: number, address: string): boolean {
    if (this.evmNetworks.includes(networkId)) {
      return this.web3Service.web3.utils.isAddress(address);
    } else if (NetworkType.Solana == networkId) {
      return this.solanaService.isValidAddress(address)
    }
    return false;
  }
}
