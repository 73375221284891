import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { AlertService } from '../shared-dlg.module';
import { UsersServiceProxy, SignUpDTO, SumSupDTO } from '../../service-proxies/service-proxies';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';

declare function launchWebSdk(apiUrl: string, flowName: string, accessToken: string, applicantEmail: string): any
declare var $: any;

@Component({
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss'],
})
export class KYCPageComponent extends ComponentBase implements OnInit {
  constructor(
    private _alertSrv: AlertService,
    private usersService: UsersServiceProxy,
    private userSessionProvider: UserSessionProvider,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super();

  }

  sumSupSettings: SumSupDTO;
  //usersParams: UserParamsDTO;

  waiting: boolean = false;
  account: string = '';

  async ngOnInit() {
    if (this.userSessionProvider.authToken) {
      this.getMyParams();
    }
    else {
      console.error('Not Auth. redirect to login page');
      this.router.navigate(["/login"]);
    }
  }

  getMyParams(): void {
    this.usersService.getSumSupSettings()
      .subscribe(result => {
        this.sumSupSettings = result;
        this.initSumAndSupIframe();
      },
        error => {
          if (error.status == 401) {
            this.userSessionProvider.finishAuth();
            console.error('401');
            this.logout();
          }
          else
            this._alertSrv.show(JSON.parse(error.response).message, 'error');
          //this.showErrorModal(JSON.parse(error.response).message);
        });
  }

  logout(): void {
    console.error('logout');
    this.router.navigate(["/login"]);
  }

  initSumAndSupIframe(): void {
    //$(".idensic_container").show();

    launchWebSdk(environment.sumAndSupURL, environment.sumAndSupFlowName, this.sumSupSettings.accessToken, this.sumSupSettings.userEmail);

    //idensic.init(
    //  '#idensic',
    //  {
    //    accessToken: result.accessToken,
    //    applicantId: result.applicantId
    //  },
    //  function (messageType: string, payload: { height: any; }) {
    //    // resizing iframe to the inner content size
    //    if (messageType == 'idCheck.onResize') {
    //      idensic.iframe.height = payload.height;
    //    }
    //  }
    //);
  }
}
