import { environment } from "src/environments/environment";

export interface MenuItem {
  readonly label: string,
  readonly routePath: string[] | string,
  readonly supportedChains: number[],
  readonly icon?: string;
  readonly childItems?: MenuItem[],
  readonly isExternal?: boolean;
}

export const menu: MenuItem[] = [
  {
    icon: "icomoon-dashboard",
    label: "Dashboard",
    routePath: "/",
    supportedChains: [56, 97, 80002],
  },
  {
    icon: "icomoon-address-book",
    label: "Address Book",
    routePath: "/address-book",
    supportedChains: [56, 97, 80002],
  },
  {
    icon: "icomoon-kys",
    label: "KYC",
    routePath: "/kyc",
    supportedChains: [80002],
  },
  {
    icon: "icomoon-market",
    label: "Deals",
    routePath: ["/deal-detail", "/deals/0","/deals/1", "/deals/2"],
    supportedChains: [56, 97, 80002],
    childItems: [{
      label: "VC Deals",
      routePath: "/deals/0",
      supportedChains: [56, 97, 80002],
    },
    {
      label: "IDO Deals",
      routePath: "/deals/1",
      supportedChains: [56, 97, 80002],
    },
    {
      label: "INO Deals",
      routePath: "/deals/2",
      supportedChains: [56, 97, 80002],
    },
    ]
  },
  // {
  //   icon: "icomoon-claim",
  //   label: "Claiming Deals",
  //   routePath: "/claiming",
  //   supportedChains: [], // means all
  // },
  {
    label: "Pools",
    icon: "icomoon-pools",
    routePath: ["/staking", "/farming", "/pool-detail"],
    supportedChains: [56, 97, 80002],
    childItems: [{
      label: "Staking Pools",
      routePath: "/staking",
      supportedChains: [56, 97, 80002],
    },
    {
      label: "Farming Pools",
      routePath: "/farming",
      supportedChains: [56, 97, 80002],
    }]
  },
  // {
  //   label: "Claim Tokens",
  //   icon: "icomoon-claim",
  //   routePath: ["/vesting", `/vesting/${environment.bsc.vestingSeedAddress}`, `/vesting/${environment.bsc.vestingStrategicAddress}`, `/vesting/${environment.bsc.vestingPrivateAddress}`, `/vesting/${environment.bsc.vestingBountyAddress}`],
  //   supportedChains: [56, 97],
  //   childItems: [{
  //     label: "Claim Seed",
  //     routePath: `/vesting/${environment.bsc.vestingSeedAddress}`,
  //     supportedChains: [56, 97],
  //   },
  //   {
  //     label: "Claim Strategic",
  //     routePath: `/vesting/${environment.bsc.vestingStrategicAddress}`,
  //     supportedChains: [56, 97],
  //   },
  //   {
  //     label: "Claim Private",
  //     routePath: `/vesting/${environment.bsc.vestingPrivateAddress}`,
  //     supportedChains: [56, 97],
  //   },
  //   {
  //     label: "Claim Bounty",
  //     routePath: `/vesting/${environment.bsc.vestingBountyAddress}`,
  //     supportedChains: [56, 97],
  //   }]
  // },
  {
    label: "Portfolio",
    icon: "icomoon-portfolio",
    routePath: "/portfolio",
    supportedChains: [56, 97, 80002],
  },
  {
    label:"Help Center",
    icon:"icomoon-help",
    isExternal: true,
    routePath:"https://help.bullperks.com/",
    supportedChains: [80002],
  }
];
