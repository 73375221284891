import { Component, OnDestroy, OnInit } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { EventBus } from '../../shared/event-bus';
import { Web3Service } from '../../shared/web3-service';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { DealDTO, DealServiceProxy } from '../../service-proxies/service-proxies';
import BigNumber from 'bignumber.js';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, from, Observable, of, OperatorFunction, pipe, Subscription, timer } from 'rxjs';
import { concatMap, map, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { DealConfig, DealConfigInterface } from './deals-config';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss'],
})
export class PortfolioComponent extends ComponentBase implements OnInit, OnDestroy {

  private selectedTabSubject = new BehaviorSubject<DealType>(DealType.VC);
  private readonly subscriptions = new Subscription();
  private allDeals$: Observable<DealDTO[]>;
  private account: string;

  private readonly vcConfig = new DealConfig(true);
  private readonly idoConfig = new DealConfig(true);
  private readonly inoConfig = new DealConfig(true);

  private readonly dealConfigByMode: DealConfigMode = {
    [DealType.VC]: this.vcConfig,
    [DealType.IDO]: this.idoConfig,
    [DealType.INO]: this.inoConfig
  }

  public readonly dealGroups: DealGroup[] = [
    {
      label: "VC",
      dealType: DealType.VC,
      loading: this.vcConfig.loading,
      nothingFound: combineLatest([this.vcConfig.loading, this.vcConfig.results]).pipe(map(([isLoading, results]) => !isLoading && !results.length)),
      results: this.vcConfig.results
    },
    {
      label: "IDO",
      dealType: DealType.IDO,
      loading: this.idoConfig.loading,
      nothingFound: combineLatest([this.idoConfig.loading, this.idoConfig.results]).pipe(map(([isLoading, results]) => !isLoading && !results.length)),
      results: this.idoConfig.results
    },
    {
      label: "INO",
      dealType: DealType.INO,
      loading: this.inoConfig.loading,
      nothingFound: combineLatest([this.inoConfig.loading, this.inoConfig.results]).pipe(map(([isLoading, results]) => !isLoading && !results.length)),
      results: this.inoConfig.results
    }
  ]

  constructor(
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private dealService: DealServiceProxy,
  ) {
    super();
  }

  public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    console.log("[LOG] tabChanged", tabChangeEvent)
    this.selectedTabSubject.next(DealType[tabChangeEvent.tab.textLabel as keyof typeof DealType]);
  }

  public ngOnInit(): void {
    this.web3Service.initWeb3();
    //TODO: test await tog et data from blockchain

    // this.eventBus.loginEvent.subscribe(result => {
    //   console.log('loginEvent subscription:' + result);
    //   this.initialize(result);
    // });
    console.log('linkedWallet');
    this.initialize(this.userSessionProvider.linkedWallet);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initialize(username: string): void {
    console.log('initialize - ' + username);
    if (this.account != username) {
      this.account = username;

      this.allDeals$ = this.getDealForUser(this.account);

      this.dealGroups.forEach(group => {
        this.subscriptions.add(
          this.selectedTabSubject.pipe(
            switchMap((dealType: DealType) => this.selectedTabSubject.value === group.dealType ? this.allDealsFilteredByDealType(dealType) : EMPTY),
            this.listenResult(group.dealType)
          ).subscribe(
            (result: DealDTO[]) => this.onResult(group.dealType, result),
            error => this.onError(group.dealType, error)
          )
        )
      });
    }
  }

  private listenResult(dealType: DealType): OperatorFunction<DealDTO[], DealDTO[]> {
    return pipe(
      tap(() => this.onBeforeLoad(dealType)),
      this.getDeals(),
    );
  }


  private getDeals(): OperatorFunction<DealDTO[], DealDTO[]> {
    return pipe(
      concatMap((deals: DealDTO[]) => {
        return deals.length ? forkJoin(deals.map(deal => this.isUserParticipated(deal))) : of([])
      }),
      map(i=> i.filter(k=> !!k))
    );
  }

  private onBeforeLoad(dealType: DealType): void {
    // todo check state control on tab change
    // const groupConfig = this.dealConfigByMode[dealType];
    // groupConfig.loading.next(true);
    // groupConfig.results.next([]);
  }

  private onResult(dealType: DealType, result: DealDTO[]): void {
    const groupConfig = this.dealConfigByMode[dealType];
    groupConfig.results.next(result);
    groupConfig.loading.next(false);
  }

  private onError(dealType: DealType, error: any): void {
    console.log(`[Error]: ${{ dealType }}`, error);
    const groupConfig = this.dealConfigByMode[dealType];
    groupConfig.loading.next(false);
  }

  private getDealForUser(username: string): Observable<DealDTO[]> {
    return timer(750).pipe(switchMapTo(this.dealService.getDealWhereUserRegistrated(username)));
  }

  private allDealsFilteredByDealType(dealType: DealType): Observable<DealDTO[]> {
    return this.allDeals$.pipe(map((deals: DealDTO[]) => deals.filter((deal: DealDTO) => deal.dealType == dealType)));
  }

  private isUserParticipated(deal: DealDTO): Observable<DealDTO | undefined> {
    return from(this.web3Service.getDealUserInfo(deal.dealAddress, this.account)).pipe(map(userInfo => {
      return userInfo && userInfo.totalPayment >  0 ? deal : undefined;
    }));
  }
}

type DealConfigMode = {
  [key in DealType]: DealConfigInterface
}

export interface DealGroup {
  label: string;
  dealType: DealType,
  nothingFound: Observable<boolean>;
  results: Observable<DealDTO[]>;
  loading: Observable<boolean>;
}

export enum DealType {
  VC = 0,
  IDO = 1,
  INO = 2,
}
