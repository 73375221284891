<main class="dashboard-main">
  <section class="dashboard-section section-alert">
    <div class="alert alert-warning align-items-lg-center" role="alert">
      <div class="alert-icon icomoon-plumpy-info"></div>
      <div class="alert-heading">
        We support ERC20 / BEP20 tokens. Rebase and deflationary tokens are not supported!
      </div>
    </div>
  </section>

  <section class="create-pool-section">
    <form #createStakingPoolForm="ngForm" (ngSubmit)="confirmForm()" >
      <div class="row mb-20">
        <div class="col-12 col-laptop-6 mb-20">
          <div class="white-card p-4">

            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="pool-staking-deposit" name="depositFeeBP" [(ngModel)]="depositFeeBP" placeholder="Deposit Fee" required [disabled]="step>1 || waiting"/>
              <label for="pool-staking-deposit">Deposit Fee</label>
            </div>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="pool-staking-token" name="stagingToken" [(ngModel)]="stagingToken" placeholder="Staking Token" required [disabled]="step>1 || waiting"/>
              <label for="pool-staking-token">Staking Token</label>
            </div>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="pool-reward-token" name="poolToken" [(ngModel)]="poolToken" placeholder="Reward Token" required [disabled]="step>1 || waiting"/>
              <label for="pool-reward-token">Reward Token</label>
            </div>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="pool-reward-supply" name="poolTokenAmount" [(ngModel)]="poolTokenAmount" placeholder="Reward Token Supply" required [disabled]="step>1 || waiting"/>
              <label for="pool-reward-supply">Reward Token Supply</label>
            </div>

            <div class="form-check form-switch mt-4">
              <input class="form-check-input"
                     type="checkbox"
                     name="hasWhiteListing"
                     id="pool-whitelisting"
                     [(ngModel)]="hasWhiteListing"
                     [disabled]="step>1 || waiting"
                     />
              <label class="form-check-label" for="pool-whitelisting">Whitelisting</label>
            </div>
          </div>
        </div>
        <div class="col-12 col-laptop-6 mb-20">
          <div class="white-card">
            <div class="white-card__header">
              <div class="label">Create Pool Fee Amount</div>
            </div>
            <div class="white-card__content">
              <div class="value value-lg">{{feeAmount}} BLP</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-20">
        <div class="col-lg-6">
          <div class="form-group white-card mb-20">
            <div class="white-card__header">
              <label>Start Timestamp <span>{{startTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}</span><span class="req">*</span></label>
            </div>
            <div class="white-card__content">
              <input type="number" placeholder="Start Timestamp" [(ngModel)]="startTimestamp" name="startTimestamp" class="form-control" required  [disabled]="step>1 || waiting"/>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group white-card mb-20">
            <div class="white-card__header">
              <label>End Timestamp <span>{{finishTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}</span><span class="req">*</span></label>
            </div>
            <div class="white-card__content">
              <input type="number" placeholder="Finish Timestamp" [(ngModel)]="finishTimestamp" name="finishTimestamp" class="form-control" required  [disabled]="step>1 || waiting"/>
            </div>
          </div>
        </div>
      </div>

      <!-- <button type="submit" class="btn btn-primary w-100" (click)="clickStep1()" [ngClass]="{'wait': waiting}" [disabled]="waiting" *ngIf="step==1">Check Fee</button>
      <button type="submit" class="btn btn-primary w-100" (click)="approveRewardTokenClick()" [ngClass]="{'wait': waiting}" [disabled]="waiting" *ngIf="step==2">Approve Reward Token</button>
      <button type="submit" class="btn btn-primary w-100" (click)="createStakingPoolTest()" [ngClass]="{'wait': waiting}" [disabled]="waiting" *ngIf="step==3">Create Pool</button> -->

        <button type="submit" *ngIf="step==1"
                class="btn btn-primary btn-icon btn-icon-right w-100" [ngClass]="{'wait': waiting}"
                [disabled]="!createStakingPoolForm.form.valid || waiting">
          Confirm
        </button>

        <div *ngIf="step==2">
          <div *ngIf="!feeTokenApproved">
            <button type="button" class="btn btn-primary" style="float:left" [ngClass]="{'wait': waiting}" (click)="toStep1()" [disabled]="waiting">Back</button>
            <button type="button" class="btn btn-primary btn-icon btn-icon-right" [ngClass]="{'wait': waiting}" [disabled]="waiting" (click)="approveFeeTokenClick()">
              Approve fee token
            </button>
          </div>
        </div>

        <div *ngIf="step==3">
          <div *ngIf="!rewardTokenApproved">
            <button type="button" class="btn btn-primary" style="float:left" [ngClass]="{'wait': waiting}" (click)="toStep1()" [disabled]="waiting">Back</button>
            <button type="button" class="btn btn-primary btn-icon btn-icon-right" [ngClass]="{'wait': waiting}" [disabled]="waiting" (click)="approveRewardTokenClick()">
              Approve reward token
            </button>
          </div>
        </div>

        <div *ngIf="step==4">
          <div *ngIf="rewardTokenApproved">
            <button type="button" class="btn btn-primary" style="float:left" [ngClass]="{'wait': waiting}" (click)="toStep1()" [disabled]="waiting">Back</button>
            <button type="button" class="btn btn-primary btn-icon btn-icon-right" [ngClass]="{'wait': waiting}" [disabled]="waiting" (click)="createStakingPoolClick()">
              Create Staking Pool
            </button>
          </div>
        </div>
    </form>
  </section>
</main>
