<info-join-deals></info-join-deals>

<section class="section-alert">
  <div class="alert alert-warning" role="alert" *ngIf="needToWaitTime">
    <div class="alert-icon icomoon-lock"></div>
    <div class="alert-heading">
      <p>You have lockup period {{needToWaitTime|showPeriod}}.</p>
      <p>If you want to shorten your time lock period, please update to one of the higher tiers.</p>
    </div>
    <div class="alert-button">
      <a href="https://help.bullperks.com/allocation-per-tier/?highlight=Lockers" target="_blank" mat-button
        color="primary">
        What Does It Mean?
      </a>
    </div>
  </div>

  <div class="alert alert-warning" role="alert" *ngIf="!userInWhitelist">
    <div class="alert-icon icomoon-info"></div>
    <div class="alert-heading">
      The deal is not yet open to join.
    </div>
  </div>
</section>

<section class="dashboard-section __deal-main-info pt-40">
  <div class="card">
    <div class="card-header d-flex flex-wrap align-items-center">
      <div class="token-name token-name-inline">
        <div class="token-name__img">
          <img [src]="deal?.logoURL?deal?.logoURL:'../../assets/images/no-image.svg'" />
        </div>
        <div class="token-name__info">
          <div class="token-title">{{deal?.name}}</div>
          <div class="token-symbol">{{deal?.rewardTokenSymbol}}</div>
        </div>
      </div>

      <button *ngIf="rewardToken" class="address-btn --copy"><span
          [cdkCopyToClipboard]="rewardToken">{{rewardToken}}</span></button>

      <div class="d-lg-flex align-items-center ms-lg-auto __deal-header-link">
        <a [href]="rewardToken | blockExplorerAddressPipe: deal?.chainId" target="_blank" mat-stroked-button
          class="__bscscan-link" *ngIf="rewardToken">
          View BSC Scan
        </a>

        <button mat-stroked-button class="__about-link ms-lg-2 btn-icon btn-icon-left" (click)="scrollToDescription()">
          <i class="icon icomoon-arrow-down"></i>
          About Project
        </button>

        <button mat-raised-button color="primary" class="__join-link ms-lg-2 btn-icon btn-icon-left"
          (click)="joinDealClick()" [disabled]="!userTier || !userInWhitelist"
          *ngIf="kycConfirmed && !(dealRegistration?.signature)">
          <i class="icon icomoon-checked"></i>
          Join Deal
        </button>

        <button mat-raised-button color="primary" class="__join-link ms-lg-2 btn-icon btn-icon-left"
          *ngIf="kycConfirmed && dealRegistration?.signature" disabled>
          <i class="icon icomoon-checked"></i>
          You are joined
        </button>
      </div>
    </div>

    <div class="card-content">
      <div class="row">
        <div class="col-md-6 col-lg-3 mb-4 mb-lg-0 order-1">
          <div class="d-flex align-items-center">
            <button *ngIf="now<finishTimestamp" (click)="addToGoogleCalendar()" mat-icon-button
              class="__add-to-google me-3" matTooltip="Add to Google Calendar" matTooltipPosition="above">
              <img src="../../assets/images/google-calendar.svg" alt="">
            </button>
            <div>
              <!-- Upcoming & Progress -->
              <div *ngIf="isUpcoming || isStarted">
                <div class="subtitle-1 mb-1" *ngIf="isUpcoming">Starts in</div>
                <span class="subtitle-1 mb-1" *ngIf="now > startTimestamp">Closes in</span>

                <div class="countdown h6 mb-1">
                  <span *ngIf="timerViewDays">{{timerViewDays}}d</span>
                  <span *ngIf="timerViewHours"> {{timerViewHours}}h</span>
                  <span *ngIf="timerViewMin && !timerViewDays"> {{timerViewMin}}m</span>
                  <span *ngIf="timerViewSec && !timerViewDays"> {{timerViewSec}}s</span>
                </div>
              </div>

              <!-- Finished -->
              <div class="subtitle-1 mb-1" *ngIf="isFinished">Deal Status</div>
              <div class="h6 mb-1" *ngIf="isFinished">Finished</div>

              <div class="small-text text-muted">{{startTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}</div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 mb-4 mb-md-0 order-2 order-md-3 order-lg-2">
          <div class="subtitle-1 mb-1">Tokens Purchased</div>
          <div class="token-name token-name-sm mb-1">
            <div class="token-name__img">
              <img [src]="deal?.logoURL?deal?.logoURL:'../../assets/images/no-image.svg'" alt="" />
            </div>
            <div class="token-name__info">
              <div class="token-title">
                {{tokensForDistribution| shiftDecimals:-rewardDecimals| number: '1.0-2'}} {{deal?.rewardTokenSymbol}}
              </div>
            </div>
          </div>
          <div class="small-text text-muted">
            <span matTooltip="1 {{paymentTokenSymbol}} = {{tokensPerETH| number: '1.0-2'}} {{deal?.rewardTokenSymbol}}"
              matTooltipPosition="above">
              {{totalRaise| shiftDecimals:-paymentDecimal| number: '1.0-2'}} {{paymentTokenSymbol}}
            </span>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 mb-4 mb-md-0 order-3 order-md-4 order-lg-3">
          <div class="subtitle-1 mb-1">Total Raise</div>
          <div class="token-name token-name-sm mb-1">
            <div class="token-name__img">
              <img [src]="deal?.logoURL?deal?.logoURL:'../../assets/images/no-image.svg'" />
            </div>
            <div class="token-name__info">
              <div class="token-title">
                {{maxDistributedTokenAmount| shiftDecimals:-rewardDecimals| number: '1.0-2'}}
                {{deal?.rewardTokenSymbol}}
              </div>
            </div>
          </div>
          <span class="small-text text-muted">
            {{getTotalRaiseETH(deal)| number: '1.0-2'}} {{paymentTokenSymbol}}
          </span>
        </div>
        <div class="col-md-6 col-lg-3 mb-md-4 mb-lg-0 order-4 order-md-2 order-lg-4">
          <div class="subtitle-1 mb-1">Deal Progress</div>

          <div class="__deal-progress">
            <div class="caption text-right text-muted mb-1">{{getDistributedLeft | shiftDecimals:-rewardDecimals|
              number: '1.0-2' }} Left</div>
            <div class="progress progress-xs">
              <div class="progress-bar" role="progressbar" [style.width.%]="getDistributedPercent"></div>
            </div>
            <div class="progress-legend d-flex justify-content-between">
              <div class="progress-legend__percent">{{getDistributedPercent| number: '1.0-2'}}%</div>
              <div class="progress-legend__value">{{tokensForDistribution | shiftDecimals:-rewardDecimals| number:
                '1.0-2'}}/{{maxDistributedTokenAmount | shiftDecimals:-rewardDecimals| number: '1.0-2'}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="dashboard-section __deal-general-info pt-40" *ngIf="usersDebtAmount || usersTotalPaymentAmount">
  <div class="row">
    <div class="col-lg-4 mb-3 mb-lg-0" *ngIf="usersDebtAmount">
      <div class="card p-4 min-h-100">
        <div class="subtitle-1 mb-1">Deal Price</div>
        <div class="h6">
          1 {{rewardTokenSymbol}} = {{tokenPrice| shiftDecimals:-paymentDecimal| number: '1.0-5'}}
          {{paymentTokenSymbol}}
        </div>
      </div>
    </div>
    <div class="col-lg-4 mb-3 mb-lg-0" *ngIf="usersDebtAmount">
      <div class="card p-4 min-h-100">
        <div class="subtitle-1 mb-1">Your Allocation</div>
        <div class="token-name token-name-sm">
          <div class="token-name__img">
            <img [src]="deal?.logoURL?deal?.logoURL:'../../assets/images/no-image.svg'" width="24" />
          </div>
          <div class="token-name__info">
            <div class="token-title h6">
              {{usersDebtAmount| number: '1.0-4'}} {{rewardTokenSymbol}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4" *ngIf="usersTotalPaymentAmount">
      <div class="card p-4 min-h-100">
        <div class="subtitle-1 mb-1">Your Deposit</div>
        <div class="token-name token-name-sm">
          <div class="token-name__img">
            <img [src]="paymentTokenSymbol | imgTokenPipe" width="24" />
          </div>
          <div class="token-name__info">
            <div class="token-title h6">
              {{usersTotalPaymentAmount| number: '1.0-4'}} {{paymentTokenSymbol}}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>

<section class="dashboard-section __deal-general-info pt-40">
  <div class="row">
    <div class="{{now<finishTimestamp ? 'col-lg-8' : 'col-lg-12'}}">
      <div class="card min-h-100">
        <!-- If deal is finished & not invested -->
        <div class="card-header d-block" *ngIf="now>finishTimestamp">
          <div class="row">
            <div class="col-lg mb-3 mb-lg-0" *ngIf="deal?.emissionDescription">
              <div class="caption text-muted mb-1">Vesting</div>
              <div class="small-text">{{deal?.emissionDescription}}</div>
            </div>
            <div class="col-lg mb-3 mb-lg-0">
              <div class="caption text-muted mb-1">Deal Price</div>
              <div class="small-text">
                1 {{rewardTokenSymbol}} = {{tokenPrice| shiftDecimals:-paymentDecimal| number: '1.0-5'}}
                {{paymentTokenSymbol}}
              </div>
            </div>
          </div>
        </div>

        <div class="card-header d-block" *ngIf="now<finishTimestamp && isCollectWalletDeal">
          <div class="d-md-flex">
            <div>
              <div class="caption text-muted mb-1">Emission Address</div>
              <div class="d-flex align-items-center mb-1">
                <span class="icon icon-sm icomoon-info text-error me-2" *ngIf="!emissionAddress"></span>
                <img [src]="deal?.targetNetwork | imgNetworkPipe" class="logo-20 me-2" />
                <div class="__emission-address small-text">{{emissionAddress?emissionAddress:'Add Emission Address by
                  clicking on Add button'}}</div>
              </div>
              <div class="caption text-muted">Your wallet address where the project tokens will be sent to you</div>
            </div>

            <div class="__emission-address__actions mt-3 mt-lg-0 ms-md-auto">
              <button mat-raised-button color="primary" class="__emission-add btn-icon btn-icon-left"
                (click)="openAddAddressDlg()" [disabled]="!account || !isAuthorized" *ngIf="!emissionAddress">
                <i class="icon icomoon-plus"></i>
                Add
              </button>
            </div>
          </div>

          <!--<div *ngIf="!userInWhitelist || !kycConfirmed || !isApprovedPaymentToken">
            <mat-form-field appearance="fill" hideRequiredMarker>
              <mat-label>Emission address</mat-label>
              <input matInput
                     type="text"
                     disabled
              >
              <mat-hint>
                The wallet address where you want the project tokens sent to you
              </mat-hint>
            </mat-form-field>
          </div>

          <div *ngIf="kycConfirmed && canPayMore && isApprovedPaymentToken">
            <mat-form-field appearance="fill" hideRequiredMarker>
              <mat-label>Emission address</mat-label>
              <input matInput
                     type="text"
                     name="emissionAddress"
                     [(ngModel)]="emissionAddress"
                     required>
              <mat-hint>
                The wallet address where you want the project tokens sent to you
              </mat-hint>
              <mat-error>Invalid wallet address</mat-error>
            </mat-form-field>
          </div>-->
        </div>

        <div class="card-content pb-0">
          <div class="row">
            <div class="col-lg-4 mb-4">
              <div class="caption text-muted mb-1">Target Network</div>
              <div class="network-name">
                <img [src]="targetNetwork | imgNetworkPipe" class="network-name__img" alt="" />
                <span class="network-name__title small-text">{{targetNetwork|networkName}}</span>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="caption text-muted mb-1">Deposit</div>
              <div class="network-name">
                <img [src]="paymentTokenSymbol | imgTokenPipe" class="network-name__img" alt="" />
                <span class="network-name__title small-text">{{paymentTokenSymbol}}</span>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="caption text-muted mb-1">Type</div>
              <div class="small-text">
                <div class="subtitle-1">{{deal?.dealType | dealTypeName}}</div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="caption text-muted mb-1">Start</div>
              <div class="small-text">{{startTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}</div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="caption text-muted mb-1">End</div>
              <div class="small-text">{{finishTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}</div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="caption text-muted mb-1">Token Generation</div>
              <div class="small-text">{{startClaimTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}</div>
            </div>
          </div>
        </div>

        <div class="card-footer" *ngIf="now<finishTimestamp">
          <div class="row">
            <div class="col-lg-8 mb-3 mb-lg-0" *ngIf="deal?.emissionDescription">
              <div class="caption text-muted mb-1">Vesting</div>
              <div class="small-text">{{deal?.emissionDescription}}</div>
            </div>
            <div class="col-lg-4">
              <div class="caption text-muted mb-1">Min. Raise</div>
              <div class="network-name">
                <span class="network-name__title small-text">{{minimumRaise| shiftDecimals:-rewardDecimals| number:
                  '1.0-2'}} {{paymentTokenSymbol}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 mt-4 mt-lg-0" *ngIf="now<finishTimestamp">
      <div class="card p-4 min-h-100">
        <div class="subtitle-1 mb-1">Your Tier</div>
        <div class="__current-tier mb-4">
          <img [src]="userTierIndex | imgTierPipe" alt="" />
          <span class="h6">{{userTierIndex|nameTierPipe}}</span>
        </div>

        <mat-form-field appearance="fill" hideRequiredMarker class="icon-form-field mb-4">
          <img [src]="paymentTokenSymbol | imgTokenPipe" />
          <mat-label>{{paymentTokenSymbol}}</mat-label>
          <input matInput type="number" [(ngModel)]="usersTicketSize" min="0" required
            [disabled]="!userTier || !account || !kycConfirmed || !userInWhitelist || 0<needToWaitTime">
          <button mat-button color="primary" class="max-btn">MAX</button>
          <mat-hint>
            <div class="d-flex align-items-center justify-content-between">
              <span>1 {{paymentTokenSymbol}} = {{tokensPerETH| number: '1.0-2'}} {{deal?.rewardTokenSymbol}}</span>

              <span *ngIf="userTier && canPayMore">Max: {{userTier.ticketSize| shiftDecimals:-paymentDecimal}}
                {{paymentTokenSymbol}}</span>
              <span *ngIf="!canPayMore">Max: 0 {{paymentTokenSymbol}}</span>
            </div>
          </mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" hideRequiredMarker class="icon-form-field mb-4">
          <img [src]="deal?.logoURL?deal?.logoURL:'../../assets/images/no-image.svg'" />
          <mat-label>{{deal?.rewardTokenSymbol}}</mat-label>
          <input matInput type="number" [(ngModel)]="usersTicketSizeInRewardTokens" min="0" required
            [disabled]="!userTier || !account || !kycConfirmed || !userInWhitelist || 0<needToWaitTime">
          <button mat-button color="primary" class="max-btn">MAX</button>
          <mat-hint>
            <div class="d-flex align-items-center justify-content-between">
              <span>1 {{rewardTokenSymbol}} = {{tokenPrice| shiftDecimals:-paymentDecimal| number: '1.0-5'}}
                {{paymentTokenSymbol}}</span>
              <span *ngIf="userTier && canPayMore">Max: {{userTierMaxTokens}} {{rewardTokenSymbol}}</span>
              <span *ngIf="!canPayMore">Max: 0 {{rewardTokenSymbol}}</span>
            </div>
          </mat-hint>
        </mat-form-field>

        <!-- If not login & can pay -->
        <div *ngIf="canPayMore">
          <div class="row gx-2">
            <!-- Approve -->
            <div class="col-lg" *ngIf="!isApprovedPaymentToken"
              matTooltip="{{!kycConfirmed ? 'You must be authorised and complete KYC for joining deals' : ''}}"
              matTooltipPosition="above">
              <button mat-raised-button color="primary" class="w-100 btn-icon btn-icon-left"
                (click)="approvePaymentClick()" [ngClass]="{'btn-loading': waiting }"
                [disabled]="waiting || !kycConfirmed || !userTier || !userInWhitelist">
                <i class="icon icomoon-checkmark"></i>
                Approve
              </button>
            </div>

            <div class="col-lg" *ngIf="allowPay && isCollectWalletDeal && 0==needToWaitTime && isAuthorized"
              matTooltip="{{!emissionAddress ? 'Add the emission address under the deal countdown' : ''}}"
              matTooltipPosition="above">
              <button mat-raised-button color="primary" class="w-100" (click)="payClickWithWalletAddress()"
                [ngClass]="{'btn-loading': waiting && isApprovedPaymentToken}"
                [disabled]="waiting || !emissionAddress || !userTier || !account|| !isApprovedPaymentToken || !userInWhitelist">
                Pay
              </button>
            </div>

            <div class="col-lg" *ngIf="allowPay && !isCollectWalletDeal && 0==needToWaitTime && isAuthorized">
              <button mat-raised-button color="primary" class="w-100" (click)="payClick()"
                [ngClass]="{'btn-loading': waiting}"
                [disabled]="waiting || !userTier || !account || !isApprovedPaymentToken || !userInWhitelist">
                Pay
              </button>
            </div>

            <div class="col-lg" *ngIf="!allowPay">
              <button mat-raised-button color="secondary" type="button"
                matTooltip="The time when you will be able to make a payment" matTooltipPosition="above" class="w-100">
                <div class="countdown d-flex align-items-center justify-content-center">
                  <span *ngIf="timerViewDays">{{timerViewDays}}d </span>
                  <span *ngIf="timerViewHours"> {{timerViewHours}}h </span>
                  <span *ngIf="timerViewMin && !timerViewDays"> {{timerViewMin}}m </span>
                  <span *ngIf="timerViewSec && !timerViewDays"> {{timerViewSec}}s</span>
                </div>
              </button>
            </div>

            <div class="col-lg" *ngIf="0<needToWaitTime" matTooltip="The time when you will be able to make a payment"
              matTooltipPosition="above">
              <button mat-raised-button color="primary" class="w-100" disabled>
                Time Lockup Ends: {{needToWaitTime|showPeriod}}
              </button>
            </div>
          </div>
        </div>

        <!-- If not login -->
        <div class="row gx-2" *ngIf="!kycConfirmed">
          <div class="col-lg" *ngIf="!isApprovedPaymentToken"
            matTooltip="You must be authorised and complete KYC for joining deals" matTooltipPosition="above">
            <button mat-raised-button color="primary" class="w-100 btn-icon btn-icon-left" disabled>
              <i class="icon icomoon-checkmark"></i>
              Approve
            </button>
          </div>
          <div class="col-lg" matTooltip="You must be authorised and complete KYC for joining deals"
            matTooltipPosition="above">
            <button mat-raised-button color="primary" class="w-100" disabled>Pay</button>
          </div>
        </div>

        <div *ngIf="kycConfirmed && !canPayMore">
          <div class="col-lg" matTooltip="You cant pay" matTooltipPosition="above">
            <button mat-raised-button color="primary" class="w-100" disabled>Pay</button>
          </div>
        </div>

        <div class="mt-4" *ngIf="needToWaitTime">
          <div class="mb-2">
            <div class="caption text-muted mb-1">Last participation:</div>
            <div class="small-text">
              {{lastParticipationTime* 1000 | date:'dd MMM, yyyy H:mm a'}}
            </div>
          </div>
          <div>
            <div class="caption text-muted mb-1">Lockup duration:</div>
            <div class="small-text">
              {{lockupDuration|showPeriod}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- TODO: Onchain deal - obsolete - refactor -->
<section class="dashboard-section __deal-general-info pt-40" *ngIf="vestingPercent">
  <div class="card p-4" *ngIf="vestingPercent">
    <div class="row">
      <div class="col-lg-6 mb-4">
        <div class="caption text-muted mb-1">Vesting %</div>
        <div class="small-text">
          {{vestingPercent}}%
        </div>
      </div>
      <div class="col-lg-6 mb-4">
        <div class="caption text-muted mb-1">Vesting Start Date</div>
        <div class="small-text">
          {{vestingStart* 1000 | date:'dd MMM, yyyy H:mm a'}} (Timestamp: {{vestingStart}})
        </div>
      </div>
      <div class="col-lg-6 mb-4">
        <div class="caption text-muted mb-1">Vesting Duration</div>
        <div class="small-text">
          {{vestingDuration | showPeriod}}
        </div>
      </div>
      <div class="col-lg-6 mb-4">
        <div class="caption text-muted mb-1">Vesting Interval</div>
        <div class="small-text">
          {{vestingInterval | showPeriod}}
        </div>
      </div>
    </div>
  </div>
</section>

<!-- TODO: Onchain deal - obsolete - refactor -->
<section class="dashboard-section __deal-general-info pt-40" *ngIf="isFinished && !isCollectWalletDeal">
  <div class="row">
    <div class="col-lg" *ngif="usersDebtAmount">
      <div class="card">
        <div class="card-content pb-0">
          <div class="row">
            <div class="col-lg-6 mb-4" *ngIf="usersDebtAmount">
              <div class="caption text-muted mb-1">Amount for claim</div>
              <div class="small-text">
                {{usersDebtAmount | number: '1.0-4'}} {{rewardTokenSymbol}}
              </div>
            </div>
            <div class="col-lg-6 mb-4" *ngIf="vestingPercent">
              <div class="caption text-muted mb-1">Vesting amount</div>
              <div class="small-text">
                {{usersDebtAmount*vestingPercent/100| number: '1.0-4' }}
              </div>
            </div>
            <div class="col-lg-6 mb-4" *ngIf="vestingPercent">
              <div class="caption text-muted mb-1">Receive amount</div>
              <div class="small-text">
                {{usersDebtAmount*(100-vestingPercent)/100| number: '1.0-4' }}
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer" *ngIf="usersDebtAmount && account">
          <button mat-raised-button color="primary" class="btn-icon btn-icon-left w-100" *ngIf="account"
            (click)="claimClick()" [ngClass]="{'btn-loading': waiting}" [disabled]="waiting || !allowClaim">
            <i class="icon icomoon-claim"></i>
            Claim your purchased tokens
          </button>
        </div>
      </div>
    </div>

    <div class="col-lg mt-3 mt-lg-0" *ngIf="usersClaimedTokensAmount || usersReleasableAmount || vestingPercent">
      <div class="card">
        <div class="card-content pb-0">
          <div class="row">
            <div class="col-lg-6 mb-4" *ngIf="usersClaimedTokensAmount">
              <div class="caption text-muted mb-1">Claimed Amount</div>
              <div class="small-text">
                {{usersClaimedTokensAmount | number: '1.0-4'}} {{rewardTokenSymbol}}
              </div>
            </div>
            <div class="col-lg-6 mb-4" *ngIf="vestingPercent">
              <div class="caption text-muted mb-1">Vested amount</div>
              <div class="small-text">
                {{usersVestedAmount | number: '1.0-4'}} {{rewardTokenSymbol}}
              </div>
            </div>
            <div class="col-lg-6 mb-4" *ngIf="vestingPercent">
              <div class="caption text-muted mb-1">Releasable amount</div>
              <div class="small-text">
                {{usersReleasableAmount | number: '1.0-4'}} {{rewardTokenSymbol}}
              </div>
            </div>
            <div class="col-lg-6 mb-4" *ngIf="vestingPercent">
              <div class="caption text-muted mb-1">Vested released amount</div>
              <div class="small-text">
                {{usersVestedReleasedAmount | number: '1.0-4'}} {{rewardTokenSymbol}}
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer" *ngIf="usersReleasableAmount">
          <button mat-raised-button color="primary" class="btn-icon btn-icon-left w-100" *ngIf="account"
            (click)="claimVesingClick()" [ngClass]="{'btn-loading': waiting}" [disabled]="waiting || !allowClaim">
            <i class="icon icomoon-claim"></i>
            Claim your vesting tokens
          </button>
        </div>
      </div>
    </div>
  </div>

</section>

<section class="dashboard-section __deal-tiers-info pt-40">
  <div class="card">
    <div class="card-header">Ticket Size and Allocation</div>
    <div class="card-content pb-0">
      <div class="deal-tiers">
        <div class="tier-group" *ngFor="let item of allTiers; index as i;">
          <div class="tier-group__title caption text-muted mb-1">{{i | nameTierPipe}}</div>
          <div class="tier-group__progress d-md-flex align-items-center">
            <div class="col-auto mb-2 mb-md-0 me-md-4 d-flex align-items-center">
              <img [src]="i | imgTierPipe" alt="" class="tier-group-logo" />
              <img [src]="paymentTokenSymbol | imgTokenPipe" class="tier-group-chain" />
              <div class="small-text">
                {{item.ticketSize| shiftDecimals:-rewardDecimals}} {{paymentTokenSymbol}}
              </div>
            </div>
            <div class="col">
              <div class="progress progress-xs">
                <div class="progress-bar" role="progressbar"
                  [style.width.%]="getDistributedPercentTier(item.allocation,item.purchasedTokens)">
                </div>
              </div>
              <div class="progress-legend d-flex justify-content-between">
                <div class="progress-legend__percent">
                  {{getDistributedPercentTier(item.allocation,item.purchasedTokens) | number: '1.0-2'}}%
                </div>
                <div class="progress-legend__value">
                  {{item.purchasedTokens| shiftDecimals:-rewardDecimals| number: '1.0-2'}} /
                  {{item.allocation| shiftDecimals:-rewardDecimals| number: '1.0-2'}}
                </div>
                <div class="progress-legend__img" *ngIf="deal?.logoURL"><img [src]="deal?.logoURL" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="dashboard-section __deal-description-info p-40" id="deal-about">
  <div class="card">
    <div class="card-header">About The Project</div>
    <div class="card-content">
      <div class="row">
        <div class="col-lg-7">
          <p>{{deal?.description}}</p>
        </div>
        <div class="col-lg-5 mb-3 mb-lg-0">
          <div class="social-list justify-content-lg-end">
            <a *ngIf="deal?.twitterURL" [href]="deal?.twitterURL" target="_blank">
              <img src="../../assets/images/social/twitter.svg" alt="">
            </a>
            <a *ngIf="deal?.mediumURL" [href]="deal?.mediumURL" target="_blank">
              <img src="../../assets/images/social/medium.svg" alt="">
            </a>
            <a *ngIf="deal?.youTubeURL" [href]="deal?.youTubeURL" target="_blank">
              <img src="../../assets/images/social/youtube.svg" alt="">
            </a>
            <a *ngIf="deal?.facebookURL" [href]="deal?.facebookURL" target="_blank">
              <img src="../../assets/images/social/facebook.svg" alt="">
            </a>
            <a *ngIf="deal?.telegramURL" [href]="deal?.telegramURL" target="_blank">
              <img src="../../assets/images/social/telegram.svg" alt="">
            </a>
            <a *ngIf="deal?.linkedInURL" [href]="deal?.linkedInURL" target="_blank">
              <img src="../../assets/images/social/linkedin.svg" alt="">
            </a>
            <a *ngIf="deal?.instagramURL" [href]="deal?.instagramURL" target="_blank">
              <img src="../../assets/images/social/instagram.svg" alt="">
            </a>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-7">
          <a [href]="deal?.url" *ngIf="deal?.url" mat-button color="primary" class="icon-link short-btn"
            target="_blank">
            <i class="icon icomoon-list"></i>
            Learn More
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
