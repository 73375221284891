import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import Web3 from 'web3';
import WalletConnectProvider from '@walletconnect/web3-provider';
import detectEthereumProvider from '@metamask/detect-provider';
import BigNumber from 'bignumber.js';

import IERC20Json from '../assets/abi/IERC20.json';
import ERC20BasicJson from '../assets/abi/ERC20Basic.json';
import DealJson from '../assets/abi/Deal.json';
import DealVestingJson from '../assets/abi/DealVesting.json';
import DealCollectWalletJson from '../assets/abi/DealCollectWallet.json';
import BLPDealJson from '../assets/abi/BLPDeal.json';
import LockerJson from '../assets/abi/Locker.json';
import DealLockupsJson from '../assets/abi/DealLockups.json';
import TGETokenVestingJson from '../assets/abi/TGETokenVesting.json';
import StakingPoolJson from '../assets/abi/StakingPool.json';
import PoolFactoryJson from '../assets/abi/PoolFactory.json';
import StakingPenaltyPoolJson from '../assets/abi/StakingPenaltyPool.json';
import PancakeRouterJson from '../assets/abi/PancakeRouter.json';
import OldLockerJson from '../assets/abi/OldLockerAbi.json';
import TierCalculatorJson from '../assets/abi/TierCalculator.json';
import MerkleDistributorJson from '../assets/abi/MerkleDistributor.json';

import { environment } from '../environments/environment';
import { EventBus } from './event-bus';
import { UserSessionProvider } from './user-session-provider';
import { NetworkNamePipe } from './pipes/networkName.pipe';
import networks from "../app/networks.data";
declare const window: any;

export class ChainError extends Error {
  constructor(message: any) {
    super(message);
    this.name = "ChainError";
  }
}


@Injectable({
  providedIn: 'root',
})

export class Web3Service {
  public MetamaskName: string = "metamask";
  public WalletconnectName: string = "walletconnect";

  private readonly IERC20Abi: any;
  private readonly ERC20BasicAbi: any;
  public readonly DealAbi: any;
  public readonly DealVestingAbi: any;
  public readonly DealCollectWalletAbi: any;
  public readonly BLPDealAbi: any;
  public readonly TGETokenVestingAbi: any;
  public readonly StakingPoolAbi: any;
  public readonly PoolFactoryAbi: any;
  public readonly StakingPenaltyPoolAbi: any;
  public readonly PancakeRouterAbi: any;
  public readonly OldLockerAbi: any;
  public readonly TierCalculatorAbi: any;
  public readonly MerkleDistributorAbi: any;
  private readonly LockerAbi: any;
  private readonly DealLockupsAbi: any;

  public web3: Web3 = new Web3();

  private walletConnectProvider: WalletConnectProvider = new WalletConnectProvider({
    rpc: {
      1: "https://mainnet.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b",
      42: "https://kovan.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b",
      //BSC mainnet 56
      56: "https://bsc-dataseed.binance.org/",
      //BSC testnet
      97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
      //Heco testnet
      256: "https://http-testnet.hecochain.com"
    },
  });

  private ethereumProvider: any;

  public get chainIdNumber(): number {
    return this.userSessionProvider.getChainId();
  };

  public get CHTToken(): string {
    return environment.bsc.CHTToken;

    ////                              testnet kovan
    //if (this.chainIdNumber === 1 || this.chainIdNumber === 42) {
    //  return environment.eth.CHTToken;
    //}
    ////                                    testnet bsc
    //else if (this.chainIdNumber === 56 || this.chainIdNumber === 97) {
    //  return environment.bsc.CHTToken;
    //}
    //throw new Error('Unsupported chain');
  }

  public get getStackingAddress(): string{
    return environment.bsc.stackingAddress;
  }

  public get dealLockups(): string {
    return environment.bsc.dealLockups;
    ////                              testnet kovan
    //if (this.chainIdNumber === 1 || this.chainIdNumber === 42) {
    //  return environment.eth.dealLockups;
    //}
    ////                                    testnet bsc
    //else if (this.chainIdNumber === 56 || this.chainIdNumber === 97) {
    //  return environment.bsc.dealLockups;
    //}
    //throw new Error('Unsupported chain');
  }

  public get dealFactory(): string {
    return environment.bsc.dealFactory;
    ////                              testnet kovan
    //if (this.chainIdNumber === 1 || this.chainIdNumber === 42) {
    //  return environment.eth.dealFactory;
    //}
    ////                                    testnet bsc
    //else if (this.chainIdNumber === 56 || this.chainIdNumber === 97) {
    //  return environment.bsc.dealFactory;
    //}
    //throw new Error('Unsupported chain');
  }

  public get poolFactory(): string {
    return environment.bsc.poolFactory;
  }

  public get pancakeRouterAddress(): string {
    return environment.bsc.pancakeRouterAddress;
  }

  public get oldLockerAddress(): string {
    return environment.bsc.oldLockerAddress;
  }

  public get tierCalculator(): string {
    return environment.bsc.tierCalculator;
  }

  public get locker(): string {
    return environment.bsc.locker;

    ////                              testnet kovan
    //if (this.chainIdNumber === 1 || this.chainIdNumber === 42) {
    //  return environment.eth.locker;
    //}
    ////                                    testnet bsc
    //else if (this.chainIdNumber === 56 || this.chainIdNumber === 97) {
    //  return environment.bsc.locker;
    //}
    //throw new Error('Unsupported chain');
  }

  constructor(private eventBus: EventBus, private userSessionProvider: UserSessionProvider) {
    console.log('Web3Service constructor');
    this.IERC20Abi = IERC20Json.abi;
    this.ERC20BasicAbi = ERC20BasicJson.abi;
    this.DealAbi = DealJson;
    this.DealVestingAbi = DealVestingJson;
    this.DealCollectWalletAbi = DealCollectWalletJson;
    this.BLPDealAbi = BLPDealJson.abi;
    this.LockerAbi = LockerJson;
    this.DealLockupsAbi = DealLockupsJson;
    this.TGETokenVestingAbi = TGETokenVestingJson.abi;
    this.StakingPoolAbi = StakingPoolJson;
    this.PoolFactoryAbi = PoolFactoryJson;
    this.StakingPenaltyPoolAbi = StakingPenaltyPoolJson;
    this.PancakeRouterAbi = PancakeRouterJson;
    this.OldLockerAbi = OldLockerJson;
    this.TierCalculatorAbi = TierCalculatorJson;
    this.MerkleDistributorAbi = MerkleDistributorJson;
    //this.initWeb3();
  }


  async initWeb3() {
    console.log('initWeb3');
    this.ethereumProvider = await detectEthereumProvider({ timeout: 500 });
    if (this.ethereumProvider) {
      this.web3 = new Web3(this.ethereumProvider);

      var metamaskChainId = this.convertChainIdToHex(await this.web3.eth.getChainId());
      // await window.ethereum.request({ method: 'eth_chainId' });
      console.log("matamask chainId: " + metamaskChainId);
      if (parseInt(metamaskChainId, 16) != this.chainIdNumber) {
        this.setWeb3OnCustomRPC();
      }
      // TODO: that = this;
      // Reload when chain was changed in metamask (without connect wallet)
      var that = this;
      if (window.ethereum) {
        window.ethereum.on('chainChanged', function (chainId: string) {
          console.log(`chainChanged: ${chainId}`);
          let chainIdNumber = parseInt(chainId, 16);
          console.log('chainIdNumber: ' + chainIdNumber);
          if (chainIdNumber != that.chainIdNumber) {
            if (environment.supportedChains.indexOf(chainIdNumber) >= 0) {
              that.userSessionProvider.setChainId(chainIdNumber);
            }
            else {
              console.log('finishSession unsupported chain');
              that.userSessionProvider.finishSession();
              return;
            }
          }
          location.reload();
        });
      }
      return;
    }

    else {
      //this.isWeb3Disabled = true;
      if (!this.web3 || !this.web3.currentProvider) {
        this.setWeb3OnCustomRPC();
      }
    }

    //await this.updateChanId();

    //this.web3 = new Web3("https://mainnet.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b");
    //this.chainId = '0x2a';

    //this.chainId = '0x01';

    //await this.WalletConnect();
  }

  private setWeb3OnCustomRPC() {
    console.log(`set custom RPC for web3. ChainId: ${this.chainIdNumber}`);
    //ETH Mainnet
    if (this.chainIdNumber == 1) {
      this.web3 = new Web3("https://mainnet.infura.io/v3/46e5f1638bb04dd4abb7f75bfd4f8898");
    }
    //Kovan
    else if (this.chainIdNumber == 42) {
      this.web3 = new Web3("https://kovan.infura.io/v3/46e5f1638bb04dd4abb7f75bfd4f8898");
    }
    //BSC
    else if (this.chainIdNumber == 56) {
      this.web3 = new Web3("https://bsc-dataseed.binance.org/");
    }
    //BSC Testnet
    else if (this.chainIdNumber == 97) {
      this.web3 = new Web3("https://data-seed-prebsc-1-s1.binance.org:8545/");
    }
    //Heco Testnet
    else if (this.chainIdNumber == 256) {
      this.web3 = new Web3("https://http-testnet.hecochain.com");
    }
  }


  //#region unlock wallet

  //async unlockWallet(): Promise<void> {
  public async unlockWalletconnect(reload = false): Promise<string> {
    var data: any = await this.WalletConnect();
    //this.account = data[0];
    this.userSessionProvider.linkWallet(data[0], this.WalletconnectName);
    this.eventBus.loginEvent.emit(data[0]);

    if (reload) {
      location.reload();
    }
    return data[0];
  }

  public async unlockMetamask(reload = false) {
    console.log('unlockMetamask');
    if (typeof window.ethereum == 'undefined') {
      //this.translate.get('MetaMask must be installed').subscribe((langResp: string) => {
      throw new ChainError('MetaMask must be installed');
      //});
      return false;
    }

    let chainId = await window.ethereum.request({ method: 'eth_chainId' });
    ////  Get Chain Id
    //TODO: check is this work in wallets
    //var walletChainIdNumber = await this.web3.eth.getChainId();

    let chainIdNumber = parseInt(chainId, 16);
    console.log('chainId: ' + chainId);
    console.log('chainIdNumber: ' + chainIdNumber);
    console.log('web3Service chainId: ' + this.chainIdNumber);

    if (this.chainIdNumber != chainIdNumber) {
      var toNetwork = networks.find(n => n.chainId == this.chainIdNumber);
      if (toNetwork.networkParams) {
        try {
          // @ts-ignore
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: toNetwork.networkParams.chainId }],
          })
          return true
        } catch (switchError: any) {

          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [toNetwork.networkParams],
              });
            } catch (addError) {
              console.error(addError);
              this.userSessionProvider.finishSession();
            }
          }
        }
      }
      else{
        this.userSessionProvider.finishSession();
        throw new ChainError(`Select ${new NetworkNamePipe().transform(this.chainIdNumber)} Network in your wallet.`);
      }
    }

    //if (environment.production) {
    //    if (chainId != '0x01' && chainId != '0x1' && chainId != '0x38') {
    //        this.showErrorModal(`Select Mainnet or BSC Network in MetaMask.`);
    //        //this.translate.get('select_right_metamask_network').subscribe((langResp: string) => {
    //        //    this.showErrorModal(langResp);
    //        //});
    //        return false;
    //    }
    //}
    //else {
    //    console.log(chainId);
    //    if (chainId != '0x2a' && chainId != '0x61') {
    //        this.showErrorModal(`Select Kovan or BSC Test Network in MetaMask.`);
    //        return false;
    //    }
    //}

    window.ethereum.enable().then((data: any) => {
      console.log("enabled");
      console.log(data);

      if (data.length > 0) {
        //this.account = data[0];
        this.userSessionProvider.linkWallet(data[0], this.MetamaskName);
        this.eventBus.loginEvent.emit(data[0]);

        //TOOD: that = this;
        var that = this;
        if (window.ethereum) {
          window.ethereum.on('accountsChanged', function (accounts: string[]) {
            console.log('accountsChanged');
            console.log(accounts);
            if (that.userSessionProvider.authEth && accounts.length > 0 && that.userSessionProvider.authEth != accounts[0]
              || accounts.length == 0) {
              that.userSessionProvider.finishAuth();
            }
            location.reload();
          })
          //window.ethereum.on('chainChanged', function (chainId: string) {
          //  console.log('chainChanged');
          //  console.log(chainId);
          //  if (chainId === "0x1")
          //    chainId = "0x01";
          //  if (chainId != that.chainId) {
          //    //if new chain is Ethereum
          //    if (chainId === '0x01' || chainId === '0x2a') {
          //      that.userSessionProvider.setETHNetwork();
          //    }
          //    //if new chain is BSC
          //    else if (chainId === '0x38' || chainId === '0x61') {
          //      that.userSessionProvider.setBSCNetwork();
          //    }
          //  }

          //  location.reload();
          //})
        }

        //TODO: remove reload, add eventBus
        if (reload) {
          location.reload();
        }
      }
    }, (reason: any) => {
      console.log("My Permission to connect to Metamask was denied");
      console.log(reason);
    })

    return true;
  }

  //#endregion

  async WalletConnect() {
    console.log('WalletConnect');
    //  Create WalletConnect Provider
    this.walletConnectProvider = new WalletConnectProvider({
      rpc: {
        1: 'https://mainnet.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b',
        42: 'https://kovan.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b',
        // BSC mainnet 56
        56: 'https://bsc-dataseed.binance.org/',
        // BSC testnet
        97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        // heco testnet
        256: 'https://http-testnet.hecochain.com'
      },
    });
    console.log(`chainIdNumber: ${this.chainIdNumber}`);
    this.walletConnectProvider.chainId = this.chainIdNumber;

    //  Enable session (triggers QR Code modal)
    let addresses = await this.walletConnectProvider.enable();
    console.log(addresses);

    //  Create Web3
    this.web3 = new Web3(this.walletConnectProvider as any);

    //  Get Chain Id
    const walletChainIdNumber = await this.web3.eth.getChainId();
    console.log('Wallet connect chainId: ' + walletChainIdNumber);
    if (this.chainIdNumber != walletChainIdNumber) {
      throw new ChainError(`Select ${new NetworkNamePipe().transform(this.chainIdNumber)} Network in your wallet.`);
      //this.userSessionProvider.finishSession();
    }

    // Subscribe to accounts change
    this.walletConnectProvider.on('accountsChanged', (accounts: string[]) => {
      console.log('accountsChanged ' + accounts);
      if (this.userSessionProvider.authEth && accounts.length > 0 && this.userSessionProvider.authEth != accounts[0]
        || accounts.length == 0) {
        this.userSessionProvider.finishAuth();
      }
      location.reload();
      this.eventBus.accountsChanged.emit(accounts)
    });

    // Subscribe to chainId change
    this.walletConnectProvider.on("chainChanged", (chainId: number) => {
      console.log("chainChanged" + chainId);

      this.eventBus.chainChanged.emit(this.convertChainIdToHex(chainId));
    });

    // Subscribe to session connection
    this.walletConnectProvider.on("connect", () => {
      console.log("connect");
      this.eventBus.walletConnect.emit("");
    });

    // Subscribe to session disconnection
    this.walletConnectProvider.on("disconnect", (code: number, reason: string) => {
      console.log(code, reason);
      this.eventBus.walletDisconnect.emit(reason);
    });
    //console.log(this.web3);
    return addresses;
  }

  public convertChainIdToHex(value: number): string {
    var hexChainId = '0x' + value.toString(16);
    if (hexChainId === '0x1')
      hexChainId = "0x01";
    return hexChainId;
  }

  async WalletDisconnect() {
    if (this.walletConnectProvider) {
      // Close provider session
      await this.walletConnectProvider.disconnect()
    }
  }

  async PersonalSign(dataToSign: string, address: string,): Promise<any> {
    return new Promise((resolve, reject) => {
      this.web3.eth.personal.sign(dataToSign, address, "", (error, resp) => {
        if (error) {
          console.log(error);
        }
        console.log(resp);
        resolve(resp);
      });
    }) as Promise<any>;
  }


  //#region web3

  //#region ERC20BasicAbi
  async GetTransactionReceipt(tx: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.web3.eth.getTransactionReceipt(tx, (error, resp) => {
        console.log(resp);
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async GetDecimals(contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.ERC20BasicAbi, contractAddress);
      contract.methods.decimals().call({}, (error: any, resp: any) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async GetTotalSupply(contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.ERC20BasicAbi, contractAddress);
      contract.methods.totalSupply().call({}, (error: any, resp: any) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async GetAllowance(account: string, tokenForspend: string, forContractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.ERC20BasicAbi, tokenForspend);
      contract.methods.allowance(account, forContractAddress).call({}, (error: any, resp: any) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async GetTokenBalance(account: string, tokenAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.ERC20BasicAbi, tokenAddress);
      contract.methods.balanceOf(account).call({}, (error: any, resp: any) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async GetContractSymbol(tokenAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.ERC20BasicAbi, tokenAddress);
      contract.methods.symbol().call({}, (error: any, resp: any) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async GetContractName(tokenAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.ERC20BasicAbi, tokenAddress);
      contract.methods.name().call({}, (error: any, resp: any) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  approve(account: string, tokenForspend: string, forContractAddress: string) {
    // new contract
    // DOC: https://web3js.readthedocs.io/en/v1.3.4/web3-eth-contract.html#new-contract
    let contract = new this.web3.eth.Contract(this.IERC20Abi, tokenForspend);

    let contractEventSource = contract.methods
      .approve(forContractAddress, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
      .send({ from: account });

    return contractEventSourceToObserves(contractEventSource);
  }

  async getEthBalance(customerAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.web3.eth.getBalance(customerAddress, (error, balance) => {
        resolve(balance);
      });
    }) as Promise<any>;
  }

  //#endregion ERC20BasicAbi

  //#region LockerAbi

  async getLockedTokenAmount(contractAddress: string, user: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.LockerAbi, contractAddress);
      contract.methods.getLockedBLP(user).call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getLockerPenaltyBP(contractAddress: string, userAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.LockerAbi, contractAddress);
      contract.methods.getPenaltyBP(userAddress).call({}, (error: any, resp: string) => {
        console.log(`getPenaltyBP`);
        console.log(resp);
        resolve(resp);
      });
    }) as Promise<any>;
  }

  // lockerDeposit(account: string, tokenAmount: number, decimal: number) {
  //   let stringTokenAmount = "0x" + new BigNumber(tokenAmount).shiftedBy(decimal).toString(16);
  //   const contract = new this.web3.eth.Contract(this.LockerAbi, this.locker);

  //   const contractEventSource = contract.methods.deposit(stringTokenAmount)
  //     .send({ from: account });

  //   return contractEventSourceToObserves(contractEventSource);
  // }

  lockerDeposit(account: string, tokenAmount: number, decimal: number) {
    let stringTokenAmount = "0x" + new BigNumber(tokenAmount).shiftedBy(decimal).toString(16);
    const contract = new this.web3.eth.Contract(this.LockerAbi, this.locker);

    return contract.methods.deposit(stringTokenAmount)
      .estimateGas({ from: account })
      .then((gasEstimate:number) => {
        const contractEventSource = contract.methods.deposit(stringTokenAmount)
          .send({ from: account, gas: gasEstimate });

        return contractEventSourceToObserves(contractEventSource);
      })
      .catch((error: any) => {
        console.error('Gas estimation failed:', error);
        throw error;
      });
}

  // lockerWithdraw(account: string, tokenAmount: number, decimal: number) {
  //   let stringTokenAmount = "0x" + new BigNumber(tokenAmount).shiftedBy(decimal).toString(16);
  //   const contract = new this.web3.eth.Contract(this.LockerAbi, this.locker);

  //   const contractEventSource = contract.methods.withdraw(stringTokenAmount)
  //     .send({ from: account });

  //   return contractEventSourceToObserves(contractEventSource);
  // }
  lockerWithdraw(account: string, tokenAmount: number, decimal: number) {
    let stringTokenAmount = "0x" + new BigNumber(tokenAmount).shiftedBy(decimal).toString(16);
    const contract = new this.web3.eth.Contract(this.LockerAbi, this.locker);

    return contract.methods.withdraw(stringTokenAmount)
      .estimateGas({ from: account })
      .then((gasEstimate:number) => {
        const contractEventSource = contract.methods.withdraw(stringTokenAmount)
          .send({ from: account, gas: gasEstimate });

        return contractEventSourceToObserves(contractEventSource);
      })
      .catch((error:any) => {
        console.error('Gas estimation failed:', error);
        throw error;
      });
}


  lockerOldWithdraw(account: string, tokenAmount: number, decimal: number) {
    let stringTokenAmount = "0x" + new BigNumber(tokenAmount).shiftedBy(decimal).toString(16);
    const contract = new this.web3.eth.Contract(this.OldLockerAbi, this.oldLockerAddress);

    const contractEventSource = contract.methods.withdraw(stringTokenAmount)
      .send({ from: account });

    return contractEventSourceToObserves(contractEventSource);
  }

  async getLockerPenalties(index: number): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.LockerAbi, this.locker);
      contract.methods.allPenalties(index).call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  //#endregion LockerAbi

  //#region TierCalculator
  async getLockedTokenAmountTierCalculator(contractAddress: string, user: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.TierCalculatorAbi, contractAddress);
      contract.methods.getLockedTokens(user).call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getUserLockingStart(user: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.TierCalculatorAbi, this.tierCalculator);
      contract.methods.userLockingStarts(user).call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  // async getDealUsersTierIndex(contractAddress: string, userAddress: string): Promise<any> {
  //   console.log("this.TierCalculatorAbi", this.TierCalculatorAbi);
  //   console.log("this.TierCalculator", this.tierCalculator);

  //   return new Promise((resolve, reject) => {
  //     let contract = new this.web3.eth.Contract(this.TierCalculatorAbi, this.tierCalculator);
  //     contract.methods.getTierIndex(userAddress, contractAddress).call({}, (error: any, resp: string) => {
  //       resolve(resp);
  //     });
  //   }) as Promise<any>;
  // }

  async getDealUsersTierIndex(contractAddress: string, userAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.TierCalculatorAbi, this.tierCalculator);
      contract.methods.getTierIndex(userAddress, contractAddress).call({}, (error: Error | null, resp: string) => {
        if (error) {
          reject(error);
        } else {
          resolve(resp);
        }
      });
    }) as Promise<any>;
  }

  //#endregion TierCalculator

  //#region PancakeRouterAbi
  async getAmountsOut(amountIn: number, path: string[]): Promise<any> {
    return new Promise((resolve, reject) => {
      let stringAmountIn = "0x" + new BigNumber(amountIn).toString(16);
      let contract = new this.web3.eth.Contract(this.PancakeRouterAbi, this.pancakeRouterAddress);
      contract.methods.getAmountsOut(stringAmountIn, path).call({}, (error: any, resp: any) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async WETH(): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.PancakeRouterAbi, this.pancakeRouterAddress);
      contract.methods.WETH().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }
  //#endregion PancakeRouterAbi

  //#region PoolFactoryAbi

  async GetStakeMasterFeeAmount(): Promise<number> {
    return new Promise((resolve, reject) => {
      console.log(this.poolFactory);
      let contract = new this.web3.eth.Contract(this.PoolFactoryAbi, this.poolFactory);
      contract.methods.feeAmount().call({}, (error: any, resp: any) => {
        resolve(resp);
      });
    }) as Promise<number>;
  }

  async GetStakeMasterFeeToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.PoolFactoryAbi, this.poolFactory);
      contract.methods.feeToken().call({}, (error: any, resp: any) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  createStakingPool(userAddress: string, stakingToken: string, poolToken: string, startTime: number, finishTime: number, poolTokenAmount: number, poolTokenDecimals: number, hasWhiteListing: boolean,
    depositFeeBP: number, feeTo: string, msgValue: number) {

    let masterContract = new this.web3.eth.Contract(this.PoolFactoryAbi, this.poolFactory);

    let stringStartTime = "0x" + new BigNumber(startTime).toString(16);
    let stringFinishTime = "0x" + new BigNumber(finishTime).toString(16);
    // let stringPoolTokenAmount = "0x" + new BigNumber(poolTokenAmount).toString(16);
    let stringDepositFeeBP = "0x" + new BigNumber(depositFeeBP).toString(16);

    let stringPoolTokenAmount = "0x" + new BigNumber(poolTokenAmount).shiftedBy(18).toString(16);

    //   function createStakingPool(
    //     IERC20 _stakingToken,
    //     IERC20 _poolToken,
    //     uint256 _startTime,
    //     uint256 _finishTime,
    //     uint256 _poolTokenAmount,
    //     bool _hasWhitelisting,
    //     uint256 _depositFeeBP,
    //     address _feeTo
    // )

    const contractEventSource = masterContract.methods
      .createStakingPool(stakingToken, poolToken, stringStartTime, stringFinishTime, stringPoolTokenAmount, hasWhiteListing, stringDepositFeeBP, feeTo)
      .send({ from: userAddress, value: msgValue });

    return contractEventSourceToObserves(contractEventSource);
  }
  //#endregion PoolFactoryAbi

  //#region StakingPoolAbi

  async rewardPerSec(address: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.StakingPoolAbi, address);
      contract.methods.rewardPerSec().call({}, (error: any, resp: any) => {
        resolve(resp);
      });

    }) as Promise<any>;
  }

  async allStakedAmount(address: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.StakingPoolAbi, address);
      contract.methods.allStakedAmount().call({}, (error: any, resp: any) => {
        resolve(resp);
      });

    }) as Promise<any>;
  }

  isWhitelisted(userAddress: string, poolAddress: string) {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.StakingPoolAbi, poolAddress);
      contract.methods.isWhitelisted(userAddress).call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  // depositToPool(userAddress: string, tokenAmount: number, tokenDecimals: number, poolAddress: string) {
  //   let poolContract = new this.web3.eth.Contract(this.StakingPoolAbi, poolAddress);
  //   let bnTokenAmount = "0x" + new BigNumber(tokenAmount).shiftedBy(tokenDecimals).toString(16);
  //   console.log(bnTokenAmount);
  //   const contractEventsSource = poolContract.methods
  //     .stakeTokens(bnTokenAmount)
  //     .send({ from: userAddress });

  //   return contractEventSourceToObserves(contractEventsSource);
  // }


async depositToPool(userAddress: string, tokenAmount: number, tokenDecimals: number, poolAddress: string) {
  let poolContract = new this.web3.eth.Contract(this.StakingPoolAbi, poolAddress);
  let bnTokenAmount = "0x" + new BigNumber(tokenAmount).shiftedBy(tokenDecimals).toString(16);
  console.log(bnTokenAmount);

  let gasEstimate;
  try {
    gasEstimate = await poolContract.methods.stakeTokens(bnTokenAmount).estimateGas({ from: userAddress });
    console.log(`Estimated gas: ${gasEstimate}`);
  } catch (error) {
    console.error("Gas estimation failed:", error);
    throw error;
  }

  const contractEventsSource = poolContract.methods
    .stakeTokens(bnTokenAmount)
    .send({ from: userAddress, gas: gasEstimate });

  return contractEventSourceToObserves(contractEventsSource);
}


  withdrawFromPool(userAddress: string, tokenAmount: number, tokenDecimals: number, poolAddress: string) {
    let poolContract = new this.web3.eth.Contract(this.StakingPoolAbi, poolAddress);
    let bnTokenAmount = "0x" + new BigNumber(tokenAmount).shiftedBy(tokenDecimals).toString(16);
    const contractEventsSource = poolContract.methods
      .withdrawStake(bnTokenAmount)
      .send({ from: userAddress });

    return contractEventSourceToObserves(contractEventsSource);
  }

  async getPoolUserInfo(userAddress: string, poolAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const poolContract = new this.web3.eth.Contract(this.StakingPoolAbi, poolAddress);
      poolContract.methods.getUserInfo(userAddress).call({}, (error: any, resp: any) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async getPoolPendingReward(contractAddress: string, user: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.StakingPoolAbi, contractAddress);
      contract.methods.pendingReward(user).call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getPoolPenaltyBP(userAddress: string, poolAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.StakingPenaltyPoolAbi, poolAddress);
      contract.methods.getPenaltyBP(userAddress).call({}, (error: any, resp: any) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async getPoolPenalties(index: number, poolAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.StakingPenaltyPoolAbi, poolAddress);
      contract.methods.allPenalties(index).call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  //#endregion StakingPoolAbi

  //#region DealLockupsAbi
  async getDealLockupsTiersLength(): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealLockupsAbi, this.dealLockups);
      contract.methods.getTiersLength().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealLockupsTiers(index: number): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealLockupsAbi, this.dealLockups);
      contract.methods.allTiers(index).call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getLastParticipations(userAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealLockupsAbi, this.dealLockups);
      contract.methods.lastParticipations(userAddress).call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }
  //#endregion DealLockupsAbi

  //#region DealAbi

  async getDealPaymentToken(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.paymentToken().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealTokenPrice(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.tokenPrice().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealRewardToken(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.rewardToken().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealDecimals(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.decimals().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealStartTimestamp(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.startTimestamp().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealFinishTimestamp(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.finishTimestamp().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealStartClaimTimestamp(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.startClaimTimestamp().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealMaxDistributedTokenAmount(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.maxDistributedTokenAmount().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealTotalRaise(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.totalRaise().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealTokensForDistribution(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.tokensForDistribution().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealMinimumRaise(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.minimumRaise().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealDistributedTokens(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.distributedTokens().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealAllowRefund(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.allowRefund().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealTiersLength(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.getTiersLength().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getDealTiers(contractAddress: string, index: number): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.allTiers(index).call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async getVestingPercent(contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.vestingPercent().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async getDealVestingAddress(dealContractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, dealContractAddress);
      contract.methods.dealVesting().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async getDealUserInfo(contractAddress: string, userAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
      contract.methods.userInfo(userAddress).call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  dealPay(contractAddress: string, userAddress: string, amountWithDecimals: string, signature: string, payByETH: boolean) {
    //let stringTokenAmount = "0x" + new BigNumber(amount).shiftedBy(decimals).toString(16);
    let wei = payByETH ? amountWithDecimals : 0;

    let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);

    //pay(uint256 _amount, bytes memory _signature) payable
    const contractEventSource = contract.methods
      .pay(amountWithDecimals, signature)
      .send({ value: wei, from: userAddress });

    return contractEventSourceToObserves(contractEventSource);
  }

  dealClaim(contractAddress: string, userAddress: string) {
    let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);

    const contractEventSource = contract.methods
      .claim()
      .send({ from: userAddress });

    return contractEventSourceToObserves(contractEventSource);
  }

  //#endregion DealAbi

  //#region DealCollectWalletAbi
  dealPayWithEmissionAddress(contractAddress: string, userAddress: string, emissionAddress: string, amountWithDecimals: string, signature: string, payByETH: boolean) {
    //let stringTokenAmount = "0x" + new BigNumber(amount).shiftedBy(decimals).toString(16);
    let wei = payByETH ? amountWithDecimals : 0;

    let contract = new this.web3.eth.Contract(this.DealCollectWalletAbi, contractAddress);

    //pay(uint256 _amount, string memory _wallet, bytes memory _signature)
    const contractEventSource = contract.methods
      .pay(amountWithDecimals, emissionAddress, signature)
      .send({ value: wei, from: userAddress });

    return contractEventSourceToObserves(contractEventSource);
  }
  //#endregion DealCollectWalletAbi

  //#region DealVestingAbi

  async getVVestingStart(contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealVestingAbi, contractAddress);
      contract.methods.vestingStart().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async getVVestingInterval(contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealVestingAbi, contractAddress);
      contract.methods.vestingInterval().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async getVVestingDuration(contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealVestingAbi, contractAddress);
      contract.methods.vestingDuration().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async getVestingReleasableAmount(contractAddress: string, userAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealVestingAbi, contractAddress);
      contract.methods.releasableAmount(userAddress).call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async getVestingForUser(contractAddress: string, userAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.DealVestingAbi, contractAddress);
      contract.methods.vestings(userAddress).call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  vestingRelease(contractAddress: string, userAddress: string) {
    let contract = new this.web3.eth.Contract(this.DealVestingAbi, contractAddress);

    const contractEventSource = contract.methods
      .release(userAddress)
      .send({ from: userAddress });

    return contractEventSourceToObserves(contractEventSource);
  }

  //#endregion DealVestingAbi

  //#region BLPDealAbi

  async getBLPVestingStart(contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.BLPDealAbi, contractAddress);
      contract.methods.vestingStart().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async getBLPVestingInterval(contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.BLPDealAbi, contractAddress);
      contract.methods.vestingInterval().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async getBLPVestingDuration(contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.BLPDealAbi, contractAddress);
      contract.methods.vestingDuration().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async getBLPReleasableAmount(contractAddress: string, userAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.BLPDealAbi, contractAddress);
      contract.methods.releasableAmount(userAddress).call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async getBLPVestingForUser(contractAddress: string, userAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.BLPDealAbi, contractAddress);
      contract.methods.vestings(userAddress).call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  blpDealRelease(contractAddress: string, userAddress: string) {
    let contract = new this.web3.eth.Contract(this.BLPDealAbi, contractAddress);

    const contractEventSource = contract.methods
      .release(userAddress)
      .send({ from: userAddress });

    return contractEventSourceToObserves(contractEventSource);
  }

  //#endregion BLPDealAbi

  //#region TGETokenVestingAbi

  async getTGEVestingStart(contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.TGETokenVestingAbi, contractAddress);
      contract.methods.start().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async getTGEVestingInterval(contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.TGETokenVestingAbi, contractAddress);
      contract.methods.interval().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async getTGEVestingDuration(contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.TGETokenVestingAbi, contractAddress);
      contract.methods.duration().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async getTGEVestingTgeTime(contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.TGETokenVestingAbi, contractAddress);
      contract.methods.tgeTime().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async getTGEVestingTgePercent(contractAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.TGETokenVestingAbi, contractAddress);
      contract.methods.tgePercent().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async getTGEReleasableAmount(contractAddress: string, userAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.TGETokenVestingAbi, contractAddress);
      contract.methods.releasableAmount(userAddress).call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  async getTGEVestingForUser(contractAddress: string, userAddress: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.TGETokenVestingAbi, contractAddress);
      contract.methods.vestings(userAddress).call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<any>;
  }

  TGEReleaseTGE(contractAddress: string, userAddress: string) {
    let contract = new this.web3.eth.Contract(this.TGETokenVestingAbi, contractAddress);

    const contractEventSource = contract.methods
      .releaseTGE(userAddress)
      .send({ from: userAddress });

    return contractEventSourceToObserves(contractEventSource);
  }

  TGERelease(contractAddress: string, userAddress: string) {
    let contract = new this.web3.eth.Contract(this.TGETokenVestingAbi, contractAddress);

    const contractEventSource = contract.methods
      .release(userAddress)
      .send({ from: userAddress });

    return contractEventSourceToObserves(contractEventSource);
  }

  //#endregion TGETokenVestingAbi

  //#region MerkleDistributorAbi

  async getClaimingDealToken(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.MerkleDistributorAbi, contractAddress);
      contract.methods.token().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  async isPausedClaimingDeal(contractAddress: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let contract = new this.web3.eth.Contract(this.MerkleDistributorAbi, contractAddress);
      contract.methods.paused().call({}, (error: any, resp: string) => {
        resolve(resp);
      });
    }) as Promise<string>;
  }

  claimTokensClaimingDeal(account: string, contractAddress: string, index: number, emissionAddress: string, amount: string, merkleProofs: string[]) {
    let masterContract = new this.web3.eth.Contract(this.MerkleDistributorAbi, contractAddress);
    const contractEventSource = masterContract.methods
      .claim(index, emissionAddress, amount, merkleProofs)
      .send({ from: account });
    return contractEventSourceToObserves(contractEventSource);
  }
  //#endregion MerkleDistributorAbi

  //#endregion web3

}

function contractEventSourceToObserves(contractEventSource: any) {
  const transactionHashSbj: Subject<string> = new Subject();
  const receiptSbj: Subject<any> = new Subject();
  const errorSbj: Subject<{
    error: any;
    receipt: any;
  }> = new Subject();

  contractEventSource
    .on('error', function (error: any, receipt: any) {
      console.log('contractEventSource error');
      console.log(error);
      errorSbj.error({ error, receipt });
      errorSbj.complete();
    })
    .on('transactionHash', function (hash: any) {
      transactionHashSbj.next(hash);
      transactionHashSbj.complete();
    })
    .on('receipt', function (receipt: any) {
      receiptSbj.next(receipt);
      receiptSbj.complete();
    });

  const transactionHash$ = transactionHashSbj
    .asObservable()
    .pipe(takeUntil(errorSbj));

  const receipt$ = receiptSbj
    .asObservable()
    .pipe(takeUntil(errorSbj));

  const error$ = errorSbj.asObservable();

  return {
    transactionHash$,
    receipt$,
    error$
  };
}
