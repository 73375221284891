import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "descriptionTierPipe"
})
export class DescriptionTierPipe implements PipeTransform {
  transform(index: number): string {
    switch (index) {
      case -1:
        return '';
      case 0:
        return '<ul><li>3 days locking period</li></ul>';
      case 1:
        return '<ul><li>3 days locking period</li></ul>';
      case 2:
        return '<ul><li>2 days locking period</li></ul>';
      case 3:
        return '<ul><li>2 days locking period</li></ul>';
      case 4:
        return '<ul><li>1 day locking period</li></ul>';
      case 5:
        return '<ul><li>No locking period</li></ul>';
      default:
        return '';
    }
  }
}
