<main class="dashboard-main">
  <section class="create-pool-section">
    <ul class="nav nav-tabs nav-tabs-filled create-deal-tab-nav tab-section nav-tabs-responsive-md"
        id="createPoolTab"
        role="tablist">
      <li class="nav-item">
        <button class="nav-link active"
                id="create-pool-link-general"
                data-bs-toggle="tab"
                data-bs-target="#create-pool-general"
                type="button"
                role="tab"
                aria-controls="create-pool-general"
                aria-selected="true">
          General
        </button>
      </li>
      <li class="nav-item">
        <button class="nav-link"
                id="create-pool-link-appearance"
                data-bs-toggle="tab"
                data-bs-target="#create-pool-appearance"
                type="button"
                role="tab"
                aria-controls="create-pool-appearance"
                aria-selected="true">
          Appearance
        </button>
      </li>
      <li class="nav-item">
        <button class="nav-link"
                id="create-pool-link-whitelist"
                data-bs-toggle="tab"
                data-bs-target="#create-pool-whitelist"
                type="button"
                role="tab"
                aria-controls="create-pool-whitelist"
                aria-selected="true">
          Whitelist
        </button>
      </li>
    </ul>

    <form action="">
      <div class="tab-content create-pool-tab-content" id="createPoolTabContent">
        <div class="tab-pane fade show active"
             id="create-pool-general"
             role="tabpanel"
             aria-labelledby="create-pool-link-general">

          <div class="row mb-20">
            <div class="col-12 col-laptop-6 mb-40">
              <div class="white-card p-4">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="pool-staking-deposit" name="depositFeeBP" [(ngModel)]="depositFeeBP" readonly/>
                  <label for="pool-staking-deposit">Deposit Fee</label>
                </div>

                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="pool-staking-token" name="stakingToken" [(ngModel)]="stakingToken" readonly/>
                  <label for="pool-staking-token">Staking Token</label>
                </div>
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="pool-reward-token" name="poolToken" [(ngModel)]="poolToken" readonly/>
                  <label for="pool-reward-token">Reward Token</label>
                </div>
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="pool-reward-supply" name="poolTokenAmount" [(ngModel)]="poolTokenAmount" readonly/>
                  <label for="pool-reward-supply">Reward Token Supply</label>
                </div>

                <!-- <div class="col-lg-6">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="pool-token-per-block" placeholder="Tokens per Block" />
                    <label for="pool-token-per-block">Tokens per Block</label>
                  </div>
                </div> -->
              </div>
            </div>

            <div class="col-12 col-laptop-6 mb-40">
              <div class="white-card">
                <div class="white-card__header">
                  <div class="label">Create Pool Fee Amount</div>
                </div>
                <div class="white-card__content">
                  <div class="value value-lg">{{feeAmount}} BLP</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <div class="form-group white-card mb-40">
                <div class="white-card__header">
                  <label>Start Timestamp <span>{{startTime * 1000 | date:'dd MMM, yyyy H:mm a'}}</span><span class="req">*</span></label>
                </div>
                <div class="white-card__content">
                  <input type="number" placeholder="Start Timestamp" [(ngModel)]="startTime" name="startTime" class="form-control" readonly/>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group white-card mb-40">
                <div class="white-card__header">
                  <label>End Timestamp <span>{{finishTime * 1000 | date:'dd MMM, yyyy H:mm a'}}</span><span class="req">*</span></label>
                </div>
                <div class="white-card__content">
                  <input type="number" placeholder="Finish Timestamp" [(ngModel)]="finishTime" name="finishTime" class="form-control" readonly/>
                </div>
              </div>
            </div>
          </div>

          <!-- <button class="btn btn-primary w-100 mt-4">Save changes</button> -->
        </div>

        <div class="tab-pane fade"
             id="create-pool-appearance"
             role="tabpanel"
             aria-labelledby="create-pool-link-appearance">
            <div class="row mb-40">
              <div class="col-lg-6">
                <div class="white-card p-4 mb-4 mb-lg-0">
                  <div class="form-floating mb-3">
                    <input type="text" class="form-control" id="pool-name" placeholder="Name" name="poolName" [(ngModel)]="poolName" required/>
                    <label for="pool-name">Name</label>
                  </div>
                  <div class="form-floating">
                    <textarea class="form-control" id="pool-description" rows="6" placeholder="Description" name="poolDescription" [(ngModel)]="poolDescription" required></textarea>
                    <label for="pool-description">Description</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mb-20">
                <!-- <div class="form-group form-group-inline white-card  mb-20">
                  <div class="white-card__header">
                    <label>Type</label>
                  </div>
                  <div class="white-card__content">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input"
                             type="radio"
                             name="pool_type"
                             id="pool-type-1"
                             value="Approved"
                             checked />
                      <label class="form-check-label" for="pool-type-1">Approved</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input"
                             type="radio"
                             name="pool_type"
                             id="pool-type-2"
                             value="Public" />
                      <label class="form-check-label" for="pool-type-2">Public</label>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="form-group form-group-inline white-card">
                  <div class="white-card__header">
                    <label>Visible for Users</label>
                  </div>
                  <div class="white-card__content">
                    <div class="form-check form-switch">
                      <input class="form-check-input"
                             type="checkbox"
                             id="pool-visibility"
                             name="visibleForUsers"
                             [(ngModel)]="visibleForUsers"
                              />
                      <label class="form-check-label"
                             for="pool-visibility"></label>
                    </div>
                  </div>
                </div> -->

              </div>
            </div>
          <button class="btn btn-primary w-100 mt-4" (click)="updateDealDb()">Save changes</button>
        </div>

        <div class="tab-pane fade"
             id="create-pool-whitelist"
             role="tabpanel"
             aria-labelledby="create-pool-link-whitelist">
          <div class="white-card p-4 mb-4 mb-lg-0">
            <div class="form-check form-switch mb-4">
              <input class="form-check-input"
                     type="checkbox"
                     name="hasWhiteListing"
                     id="pool-whitelisting"
                     [(ngModel)]="hasWhiteListing"
                     />
              <label class="form-check-label" for="pool-whitelisting">Whitelisting</label>
            </div>

            <div class="form-floating">
              <textarea class="form-control" id="pool-whitelist" rows="6" placeholder="White list addresses (splitted by space or comma)"></textarea>
              <label for="pool-whitelist">White list addresses (splitted by space or comma)</label>
            </div>
          </div>

          <button class="btn btn-primary w-100 mt-4">Save changes</button>
        </div>
      </div>
    </form>
  </section>
</main>
