<section class="dashboard-section p-40">
  <div class="sign-up-form card" *ngIf="step==1">
    <div class="card-content text-center">
      <div class="subtitle-1 mb-4">Unlock wallet</div>
      <i class="icon icomoon-unlock __icon"></i>
      <button type="button"
              mat-raised-button
              color="primary"
              class="w-100"
              [ngClass]="{'btn-loading': waiting}"
              [disabled]="waiting"
              (click)="unlockWallet()">
        Unlock wallet
      </button>
    </div>
  </div>


  <!--<div class="white-card sign-up-form mb-20" *ngIf="step==1">
    <div class="white-card__header">
      <div class="label">Step 2</div>
    </div>
    <div class="white-card__content">
      <form>
        <button type="button" class="btn btn-primary w-100" (click)="unlockWallet()"
                [ngClass]="{'wait': waiting}"
                [disabled]="waiting">
          Sign request
        </button>
      </form>
    </div>
  </div>-->

  <div class="sign-up-form card" *ngIf="step==2">
    <div class="card-content text-center">
      <div class="subtitle-1 mb-4">Registration</div>
      <i class="icon icomoon-unlock __icon"></i>
      <button type="button"
              mat-raised-button
              color="primary"
              class="w-100"
              [ngClass]="{'btn-loading': waiting}"
              [disabled]="waiting"
              (click)="confirmSignUp()"
      >
        Sign and Finish Registration
      </button>
    </div>
  </div>

  <div class="sign-up-form card" *ngIf="step==4">
    <div class="card-content text-center">
      <div class="subtitle-1 mb-4">Registration Finished</div>
      <i class="icon icomoon-checked __icon"></i>
      <div class="small-text mute-80">
        You have successfully registered!
      </div>

      <a href="/" mat-raised-button  color="primary" class="w-100">Dashboard</a>
    </div>
  </div>
</section>

