import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-mobile-header',
  templateUrl: './app-mobile-header.component.html',
  // styleUrls: ['./app-footer.component.scss']
})
export class AppMobileHeaderComponent implements OnInit {
  constructor(
    private router: Router
  ) {}

  public routerPath: string;

  ngOnInit(): void {
    this.router.events.subscribe(value => {
      this.routerPath = this.router.url.toString();
      let urlParts = this.routerPath.split('?');
      if (urlParts.length >= 2) {
        this.routerPath = urlParts[0];
      }
      document.body.classList.remove('mob-menu-active');
    });
  }
  toggleMenu() {
    if (document.body.classList.contains('mob-menu-active')) {
      document.body.classList.remove('mob-menu-active');
    } else {
      document.body.classList.add('mob-menu-active');
    }
  }
}
