import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "imgTierPipe"
})
export class ImgTierPipe implements PipeTransform {
  transform(index: number): string {
    if (index == -1)
      return "/assets/images/tier/none.png";
    if (index == 0)
      return "/assets/images/tier/cooper-bull.png";
    if (index == 1)
      return "/assets/images/tier/bronze-bull.png";
    if (index == 2)
      return "/assets/images/tier/silver-bull.png";
    if (index == 3)
      return "/assets/images/tier/golden-bull.png";
    if (index == 4)
      return "/assets/images/tier/titanium-bull.png";
    if (index == 5)
      return "/assets/images/tier/platinum-bull.png";
    return "/assets/images/tier/none.png";
  }
}
