<main class="dashboard-main" [ngClass]="{'loading-page': waiting}">

  <section class="__deal-list">
    <div class="card-list per-row-3">
      <div *ngFor="let item of allClaimingDeals$| async; index as i" [ngClass]="item.isLocked?'card --disabled':'card'">

        <div class="tooltip-inside" *ngIf="item.isLocked">
          Deal the deal is temporarily locked. Recalculating distribution.
          {{item.comment}}
        </div>

        <div class="card-content">
          <div class="token-name">
            <div class="token-name__img">
              <img [src]="item?.imageURL?item?.imageURL:'../../assets/images/no-image.svg'" class="rounded-circle">
            </div>
            <div class="token-name__info">
              <div class="token-title">{{item?.name}}</div>
              <div class="token-symbol">{{item?.tokenSymbol}}</div>
            </div>
          </div>

          <button class="address-btn --copy mb-4 w-100" matTooltip="Emission Address">
            <span [cdkCopyToClipboard]="item?.emissionAddress">{{item?.emissionAddress}}</span>
          </button>

          <div class="row __deal-info">
            <div class="col-6 __network">
              <div class="caption">Target Network</div>
              <div class="network-name">
                <img class="network-name__img" src="{{item?.chainId | imgNetworkPipe}}" alt="">
                <span class="network-name__title">{{item?.chainId | networkName}}</span>
              </div>
            </div>
            <div class="col-6 __progress">
              <div class="caption">Claiming Progress</div>
              <div class="progress progress-sm">
                <div class="progress-bar"
                     role="progressbar"
                     [style.width.%]="getClaimedPercent(item?.claimedAmount,item?.userTotalAmount)">
                </div>
              </div>
              <div class="progress-legend">
                <div class="progress-legend__percent">{{getClaimedPercent(item?.claimedAmount,item?.userTotalAmount)| number: '1.0-2'}}%</div>
                <div class="progress-legend__value">{{item?.claimedAmount| shiftDecimals: -item?.tokenDecimal | number: '1.0-2'}} / {{item?.userTotalAmount | shiftDecimals: -item?.tokenDecimal | number: '1.0-2'}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <button mat-raised-button color="primary" class="w-100 mb-12"
                  [disabled]="item?.availableAmount"
                  (click)="claimTokensClaimingDeal(item.address,item.index, item.emissionAddress, item.availableAmount,item.merkleProofs)"
          >
            Claim {{item?.availableAmount| shiftDecimals: -item?.tokenDecimal | number: '1.0-2'}} {{item?.tokenSymbol}}
          </button>
          <button mat-stroked-button class="w-100" *ngIf="item?.dealAddress"
                  (click)="navigateToViewDeal(item?.dealAddress)">
            Original Deal Page
          </button>
        </div>
      </div>
    </div>
  </section>
</main>
