import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ComponentBase } from '../../shared/component-base';
import { EventBus } from '../../shared/event-bus';
import { Web3Service } from '../../shared/web3-service';
import { DlgContractService } from '../dlg-contract.service';
import { DlgLockerModalComponent } from '../dlg-locker-modal';
import { AlertService } from '../shared-dlg.module';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { AccountServiceProxy, UserDTO, UsersServiceProxy } from '../../service-proxies/service-proxies';
import { Router } from '@angular/router';
import { DlgWalletComponent } from '../dlg-wallet';
import { throwError, merge } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { DlgUnlockWalletComponent } from '../dlg-unlock-wallet';
import { BigNumber } from 'bignumber.js';
import { DlgLockerInfoComponent } from '../dlg-locker-info';
import { DlgTierInfoComponent } from '../dlg-tier-info';
import { ChartComponent } from 'angular2-chartjs';
import { KYC_required } from 'src/internal/kyc.decorator';

@Component({
  selector: 'main-page',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})

export class MainPageComponent extends ComponentBase implements OnInit, OnDestroy {
  constructor(
    private _dialog: MatDialog,
    private _dlgContractSrv: DlgContractService,
    private _alertSrv: AlertService,
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private usersService: UsersServiceProxy,
    private router: Router,
  ) {
    super();
  }

  waiting: boolean = false;
  account: string = '';

  updateUserTimerId: NodeJS.Timeout;

  userWalletBalance: string;
  totalUserBlpBalance: string;
  lockerOldUserBalance: string;
  lockerUserBalance: string;
  stakingUserBalance: string;
  farmingUserBalance: string;

  lockedTotalBalance: string;
  penaltyAmount: string;
  userTierIndex: number = -1;
  nextTierIndex: number;
  maxTierValue: number;
  isMaxTier = false;
  currentTierProgress:number = 0;
  nextTierValue: string;
  nextTierNeedValue: any;

  allTiers: any[] = new Array();
  allPenalties: any[] = new Array();
  userLockingStartTimestamp: number;
  allTiersLength: number = 0;

  now = Math.floor(Date.now() / 1000);
  // Last participation time
  lastParticipationTime: number = 0;
  lockupDuration: number = 0;

  lockedBalancePercent: number = 0;
  stakingPercent: number = 0;
  chartData: object;
  chartOptions = {
    offset: 0,
    aspectRatio: 1,
    responsive: true,
    cutoutPercentage: 92,
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
    animation: {
      animateRotate: false,
    }
  };

  isAuthorized: boolean = false;

  async ngOnInit() {
    this.isAuthorized = this.userSessionProvider.isAuthorized;
    await this.web3Service.initWeb3();
    if (this.web3Service.web3) {
      this.updateContractData();
    }

    if (this.userSessionProvider.linkedWallet) {
      this.eventLogin(this.userSessionProvider.linkedWallet);
    }
    this.eventBus.loginEvent.subscribe(result => {
      // console.log('loginEvent subscription:' + result);
      this.eventLogin(result);
    });

    this.eventBus.logoutEvent.subscribe(result => {
      // console.log('logoutEvent subscription:' + result);
      this.eventLogout();
    });

    this.eventBus.logoutEvent.subscribe(result => {
      // console.log('logoutEvent subscription:' + result);
      this.eventLogout();
    });

    this.eventBus.needUpdateUsersInfo.subscribe(result => {
      // console.log('needUpdateUsersInfo subscription:' + result);
      this.updateUserData();
      this.updateTokensGraph();
    });
  }

  async ngOnDestroy() {
    if (this.updateUserTimerId) {
      clearInterval(this.updateUserTimerId);
    }
  }

  async eventLogin(username: string) {
    // console.log('eventLogin');
    // console.log(username);
    if (this.account != username) {
      this.account = username;

      this.updateUserData();
      this.updateTokensGraph();

      this.updateUserTimerId = setInterval(() => {
        this.updateUserData();
      }, this.expectedBlockTime);

      // this.getTokensDebts(username);
    }
  }

  eventLogout(): void {
    // console.log('signOut');
    this.account = '';

    // this.tokenDebt = 0;
    // this.totalInvestedETH = 0;
    // this.ethBalance = 0;
    // this.totalStakeETH = null;
    // this.totalBuyToken = null;
    // this.myClaimedTokensAmount = null;
    // this.tokensDebt = new Array();

    if (this.updateUserTimerId) {
      clearInterval(this.updateUserTimerId);
    }
  }

  async updateContractData() {
    let tiesrLenght = parseInt(await this.web3Service.getDealLockupsTiersLength());
    for (let i = 0; i < tiesrLenght; i++) {
      let tier = await this.web3Service.getDealLockupsTiers(i);
      //console.log(tier);
      this.allTiers.push(tier);
      this.allTiersLength = tiesrLenght;
    }
    this.updateUserData();
    this.updateTokensGraph();

    for (let i = 0; i < 5; i++) {
      let penalties = await this.web3Service.getLockerPenalties(i);
      //console.log(penalties);
      if(penalties != null)
        this.allPenalties.push(penalties);
    }
    this.userLockingStartTimestamp = parseInt(await this.web3Service.getUserLockingStart(this.account));
  }

  public get isLockedBalanceExist(): boolean
  {
    let tkn = parseInt(this.lockerOldUserBalance);
    return tkn > 0;
  }

  async updateUserData() {
    if (this.account) {
      this.userWalletBalance = await this.web3Service.GetTokenBalance(this.account, this.web3Service.CHTToken);
      this.lockerUserBalance = await this.web3Service.getLockedTokenAmount(this.web3Service.locker, this.account);
      this.stakingUserBalance = await this.web3Service.GetTokenBalance(this.account, this.web3Service.getStackingAddress);
      console.log("this.lockerUserBalance", this.lockerUserBalance);
      console.log("this.stakingUserBalance", this.stakingUserBalance);
      this.lockerOldUserBalance = await this.web3Service.getLockedTokenAmount(this.web3Service.oldLockerAddress, this.account);
      this.lockedTotalBalance = await this.web3Service.getLockedTokenAmountTierCalculator(this.web3Service.tierCalculator, this.account);
      //  return (sumWithdrawAmount, sumFeeAmount);
      let penaltiesData = await this.web3Service.getLockerPenaltyBP(this.web3Service.locker, this.account);
      this.penaltyAmount = String(penaltiesData[1]);
      let lockedBalanceBn = new BigNumber(this.lockedTotalBalance);
      let tierIndex = 0;
      let nextIndex = 0;
      let maxTier = 0;
      let success = false;
      const lastIndex = this.allTiers.length - 1;
      for (let i = 0; i < this.allTiers.length; i++) {
        maxTier = this.allTiers[i].blpAmount;
        if (this.allTiers[i] && lockedBalanceBn.isGreaterThanOrEqualTo(new BigNumber(this.allTiers[i].blpAmount))
          //Check that next level is higher than previous
          && new BigNumber(this.allTiers[i].blpAmount).isGreaterThanOrEqualTo(new BigNumber(this.allTiers[tierIndex].blpAmount))) {
          tierIndex = i;
          nextIndex = i + 1;
          success = true;
        }
      }
      if (nextIndex > lastIndex) {
        nextIndex = lastIndex;
      }
      this.maxTierValue = maxTier;
      if (success) {
        this.userTierIndex = tierIndex;
      } else {
        this.userTierIndex = -1;
      }
      this.nextTierIndex = nextIndex;
      // Is current tier is max & filled
      if (this.allTiersLength === tierIndex && parseInt(this.lockerUserBalance) >= maxTier) {
        this.isMaxTier = true;
        this.currentTierProgress = 100;
      } else {
        const nextTierValue = this.allTiers[nextIndex].blpAmount;
        const leftToNext = parseInt(nextTierValue) - parseInt(this.lockerUserBalance);
        if (leftToNext > 0 ) {
          this.nextTierNeedValue = leftToNext;
        }
        this.currentTierProgress = parseInt(this.lockerUserBalance) * 100 / parseInt(nextTierValue);
        this.nextTierValue = nextTierValue;
      }
      this.web3Service.getDealLockupsTiers(this.userTierIndex).then(async (resp) => {
        this.lockupDuration = parseInt(resp[1]);
      });

      this.web3Service.getLastParticipations(this.account).then((resp) => {
        this.lastParticipationTime = parseInt(resp);
      });
    }
  }

  async updateTokensGraph() {
    const userWalletBalance = Number(this.userWalletBalance);
    /*TODO: Get staking & farming*/
    const stakingValue = Number(this.stakingUserBalance) || 0;
    const totalBLP = (Number(this.lockerUserBalance) || 0) + stakingValue;
    // const farmingValue = 0;
    this.totalUserBlpBalance = String(totalBLP);
    this.stakingPercent = Math.round(stakingValue * 100 / totalBLP) || 0;
    // const farmingPercent = Math.round(farmingValue * 100 / totalBLP) || 0;
    const blpBalancePercent = Math.round(userWalletBalance * 100 / totalBLP) || 0;
    const blpLockedPercent = Math.round(Number(this.lockerUserBalance) * 100 / totalBLP) || 0;
    this.lockedBalancePercent = blpLockedPercent;
    this.chartData = {
      labels: ['Staking', 'Locked-In'],
      datasets: [
        {
          data: [this.stakingPercent, blpLockedPercent],
          backgroundColor: ['#131BCB', '#31C2B9'],
          borderWidth: 0,
        }
      ]
    };
  }

  @KYC_required
  async lockTokenClick(IsLockedMode: boolean) {
    if (this.account) {
      const dialogRef = this._dialog.open(DlgLockerModalComponent, {
        panelClass: ['dlg-light', 'dlg-small'],
        scrollStrategy: new NoopScrollStrategy(),
      });
      dialogRef.disableClose = true;
      dialogRef.componentInstance.IsLockedMode = IsLockedMode;

      dialogRef.afterClosed().subscribe(result => {
        this.updateUserData();
        this.updateTokensGraph();
      });
    }
    else {
      this.unlockWalletClick();
    }
  }

  async unlockWalletClick() {
    const dialogRef = this._dialog.open(DlgUnlockWalletComponent, {
      panelClass: ['dlg-light', 'dlg-small'],
      scrollStrategy: new NoopScrollStrategy(),
    });
  }
  async lockTokenOldClick(IsLockedMode: boolean) {
    if (this.account) {
      const dialogRef = this._dialog.open(DlgLockerModalComponent, {
        panelClass: ['dlg-light', 'dlg-small'],
        scrollStrategy: new NoopScrollStrategy(),
      });
      dialogRef.disableClose = true;
      dialogRef.componentInstance.IsLockedMode = IsLockedMode;
      dialogRef.componentInstance.isOldLocker = true;

      dialogRef.afterClosed().subscribe(result => {
        this.updateUserData();
        this.updateTokensGraph();
      });
    }
    else {
      this.unlockWalletClick();
    }
  }

  async unlockWalletOldClick() {
    const dialogRef = this._dialog.open(DlgUnlockWalletComponent, {
      panelClass: ['dlg-light', 'dlg-small'],
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  async openUnlockInfoDlg() {
    const dialogRef = this._dialog.open(DlgLockerInfoComponent, {
      panelClass: ['dlg-light', 'dlg-small'],
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  async showTierInfoDlg(i: number) {
    const dialogRef = this._dialog.open(DlgTierInfoComponent, {
      panelClass: ['dlg-light', 'dlg-small'],
      scrollStrategy: new NoopScrollStrategy(),
    });
    let leftToTier = Number(this.allTiers[i].blpAmount) - Number(this.lockerUserBalance);
    if (leftToTier < 0) {
      leftToTier = 0;
    }
    dialogRef.componentInstance.tierId = i;
    dialogRef.componentInstance.tierProgress = this.getTierPercent(i, this.allTiers[i].blpAmount);
    dialogRef.componentInstance.tierAmount = this.allTiers[i].blpAmount;
    dialogRef.componentInstance.currentAmount = this.lockerUserBalance;
    dialogRef.componentInstance.leftAmount = String(leftToTier);
  }

  public get needToWaitTime(): number {
    const needTiWait = this.lastParticipationTime + this.lockupDuration - this.now;
    if (needTiWait > 0) {
      return needTiWait;
    } else {
      return 0;
    }
  }

  public getTierPercent(i: number, current: any): number {
    if (current && i <= this.nextTierIndex) {
      return Number(this.lockerUserBalance) * 100 / Number(current);
    }
    return 0;
  }
}
