import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import {UserSessionProvider} from '../../shared/user-session-provider';
import {UsersServiceProxy} from '../../service-proxies/service-proxies';
import { menu, MenuItem } from './menu';

@Component({
  selector: 'app-aside',
  templateUrl: './app-aside.component.html',
  styleUrls: ['./app-aside.component.scss'],
})
export class AppAsideComponent implements OnInit {

  public menuItems: MenuItem[];

  constructor(
    private router: Router,
    private userSessionProvider: UserSessionProvider,
    private usersService: UsersServiceProxy
  ) {
  }

  public routerPath: string;
  public routerPathAddress: string;
  public vestingSeedAddress: string = environment.bsc.vestingSeedAddress;
  public vestingStrategicAddress: string = environment.bsc.vestingStrategicAddress;
  public vestingPrivateAddress: string = environment.bsc.vestingPrivateAddress;
  public vestingBountyAddress: string = environment.bsc.vestingBountyAddress;

  kycConfirmed: boolean = false;

  ngOnInit(): void {
    const chainId = this.userSessionProvider.getChainId();
    this.menuItems = menu.filter(i=>  i.supportedChains.includes(chainId) || i.supportedChains.length == 0);
    this.router.events.subscribe(value => {
      this.routerPath = this.router.url.toString();
      let urlParts = this.routerPath.split('?');
      if (urlParts.length >= 2) {
        this.routerPath = urlParts[0];
        let urlQuery = urlParts[1].split('=');
        this.routerPathAddress = urlQuery[1];
      }
    });

    const elementsArray = document.querySelectorAll('.sidenav__nav a.nav-link');

    elementsArray.forEach(function(elem) {
      elem.addEventListener('click', function() {
        document.body.classList.remove('mob-menu-active');
      });
    });

    console.info("before isAuthorized");
    if (this.userSessionProvider.isAuthorized) {
      console.info("isAuthorized");
      this.usersService.getMe()
        .subscribe(
          result => {
            console.info("result.kycConfirmed");
            this.kycConfirmed = result.kycConfirmed;
          },
          error => {
            console.error("result.kycConfirmed");
            this.processServiceError(error);
          }
        );
    }
  }
  processServiceError(error: any) {
      console.error(JSON.parse(error.response).message);
  }
  toggleSubMenu(event: any){
    const item = event.target.nextSibling;
    if (item.classList.contains('show')) {
      item.classList.remove('show');
    } else {
      item.classList.add('show');
    }
  }

  // not used yet
  public isMenuAvailable(menuGroup : string): boolean {
    const chainId = this.userSessionProvider.getChainId();
    return !menu.filter(menuItem => menuItem.supportedChains.includes(chainId))
  }
}
