export const environment = {
  production: true,
  bsc: {
    CHTToken: '0x8Fe1E6EC1fe149A525008FD4f81009Aec7bf787a',
    dealFactory: '0x5B3b939Ea3a5Dda4A4aa07B70E62Aef29188Bc7B',
    dealCollectWalletFactory: '0x37bb7e930a34575415261D0994EB507cbAEAF5D0',
    locker: '0xd915f1cc1Cbe1131740c279085E1214538987773',
    dealLockups: '0x20A01900D77d914a8288baB06647E189236dEf22',
    poolFactory: '0xdB388D47B7F45e75987c1F505e9068f60200f054',
    vestingSeedAddress: '0x0000000000000000000000000000000000000000',
    vestingStrategicAddress: '0x0000000000000000000000000000000000000000',
    vestingPrivateAddress: '0x0000000000000000000000000000000000000000',
    vestingBountyAddress: '0x0000000000000000000000000000000000000000',
    pancakeRouterAddress: '0x354bc1cFe9959b96beD409859a726263C6C56C81', //not changed address
    oldLockerAddress: '0x74f63ABaa4300c0619122a61e4A81406511f358d', //not changed address
    tierCalculator: '0xe27CD48B6Db40ab8Ffc0Cc1BCafEB15B21442E1c',

    stackingAddress: '0x61586CCE5CdE8268d1800c25d4f8eDE481431926',
    networkTypes: [
      { id: 1, label: 'ETH', env:'prod' },
      { id: 56, label: 'BSC', env:'prod' },
      { id: 137, label: 'Polygon', env:'prod' },
      { id: -100, label: 'Solana', env:'prod' },
      { id: 97, label: 'BSC Testnet', env:'dev' },
      { id: 42, label: 'Kovan', env:'dev' },
      { id: 80002, label: 'Amoy Testnet', env:'prod' }
    ],
  },
  remoteServiceBaseUrl: "https://dev.chateau.capital",
  sumAndSupURL: 'https://api.sumsub.com',
  sumAndSupFlowName: 'basic-kyc',
  defaultChainId: 80002,
  supportedChains: [1, 56, 137, 97, 42, 80002],
  captchaSiteKey: "6LfilzUbAAAAAAkSXL_4CUOu9hS1Mkf4TAozctSY"
};
