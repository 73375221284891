<section class="pools-section">
  <div class="__pools-list">
    <mat-tab-group
      mat-align-tabs="start"
      class="fullpage-tabs deals-tabs"
      selectedIndex="4"
      disableRipple
    >
      <mat-tab label="Public Pools">
        <div class="card not-found" *ngIf="!waitingPools && publicStakingPools.length == 0">
          <div class="icon icomoon-box"></div>
          <div class="not-found__text">No public pools for now</div>
        </div>

        <div class="card-list per-row-3">
          <div class="pool-card card cursor-pointer" *ngFor="let item of publicStakingPools; index as i">
            <pool-viewer [item]="item"></pool-viewer>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Verified Pools">
        <div class="card not-found" *ngIf="!waitingPools && verifiedStakingPools.length == 0">
          <div class="icon icomoon-box"></div>
          <div class="not-found__text">No verified pools for now</div>
        </div>

        <div class="card-list per-row-3">
          <div class="pool-card card cursor-pointer" *ngFor="let item of verifiedStakingPools; index as i">
            <pool-viewer [item]="item"></pool-viewer>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Closed Pools">
        <div class="card not-found" *ngIf="!waitingPools && closedStakingPools.length == 0">
          <div class="icon icomoon-box"></div>
          <div class="not-found__text">No closed pools for now</div>
        </div>

        <div class="card-list per-row-3">
          <div class="pool-card card cursor-pointer" *ngFor="let item of closedStakingPools; index as i">
            <pool-viewer [item]="item"></pool-viewer>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="All">
        <mat-spinner *ngIf="waitingPools" class="__loading" diameter="40"></mat-spinner>

        <div class="card not-found" *ngIf="!waitingPools && allStakingPools.length == 0">
          <div class="icon icomoon-box"></div>
          <div class="not-found__text">No pools for now</div>
        </div>

        <div class="card-list per-row-3">
          <div class="pool-card card cursor-pointer" *ngFor="let item of allStakingPools; index as i">
            <pool-viewer [item]="item"></pool-viewer>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</section>
