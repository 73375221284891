import { Component, OnInit, HostBinding, HostListener } from '@angular/core';
import { Web3Service } from '../../shared/web3-service';
import networks from '../networks.data';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { AlertService } from '../shared-dlg.module';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from "@angular/router";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './app-dashboard-header.component.html',
  styleUrls: ['./app-dashboard-header.component.scss']
})
export class AppDashboardHeaderComponent implements OnInit {
  isMenu = false;
  @HostBinding('attr.is-menu') get _isMenu() {
    if (this.isMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return this.isMenu;
  }

  @HostListener('wheel', ['$event'])
  onScroll($event: MouseEvent) {
    if (this.isMenu) {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  constructor(
    public web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private _alertSrv: AlertService,
    private _dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  public routerPath: string;
  public routerPathAddress: string;
  public vestingSeedAddress: string = environment.bsc.vestingSeedAddress;
  public vestingStrategicAddress: string = environment.bsc.vestingStrategicAddress;
  public vestingPrivateAddress: string = environment.bsc.vestingPrivateAddress;
  public vestingBountyAddress: string = environment.bsc.vestingBountyAddress;
  public availableNetworks:any[];
  public networkData: any[];

  ngOnInit(): void {
    this.router.events.subscribe(value => {
      this.routerPath = this.router.url === "/" ? "/" : this.router.url.split('/')[1];
      let urlParts = this.router.url.split('/');
      if (urlParts.length >= 3 || this.router.url.includes('?')) {
        this.routerPath = this.routerPath.includes('?') ? this.routerPath.split('?')[0] : this.routerPath;
        this.routerPathAddress = urlParts[2];
      }
    });

    this.availableNetworks = environment.supportedChains;
    this.networkData = networks;
  }

  backToPrev() {
    window.history.back();
  }


  public getNetworkName(networkId: number): string{
    return this.networkData.find(i=>i.chainId == networkId).name;
  }

  async selectNetworkClick(chainId: number): Promise<boolean> {
    console.log("selectNetworkClick", chainId);
    if (this.web3Service.chainIdNumber == chainId) {
      //The same network
      console.log("same network",this.web3Service.chainIdNumber,  chainId);
      this._dialog.closeAll();
      return false;
    }

    var toNetwork = networks.find((n) => n.chainId == chainId);

    const provider = window.ethereum;
    //If exist metamask and connected account
    //TODO: get account from web3
    if (provider && this.userSessionProvider.linkedWallet) {
      if (toNetwork.networkParams) {
        try {
          // @ts-ignore
          await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId:toNetwork.networkParams.chainId}],
          });
          return true;
        } catch (switchError: any) {

          if (switchError.code === 4902) {
            try {
              // @ts-ignore
              await provider.request({
                method: 'wallet_addEthereumChain',
                params: [toNetwork.networkParams],
              });
            } catch (addError) {
              console.error(addError);
              return false;
            }
          }
          return false;
        }
      } else {
        this._alertSrv.show(
          `Select ${toNetwork.name} Network in your wallet.`,
          'error'
        );
        return false;
      }
    } else {
      console.log("set chain id", toNetwork.chainId);
      this.userSessionProvider.setChainId(toNetwork.chainId);
      location.reload();
      return false;
    }
  }
}
