<header class="dashboard-header">
  <div class="dashboard-header__title">
    <h1 class="header-title" [ngSwitch]="routerPath">
      <span *ngSwitchCase="'/'"><i class="icomoon-dashboard"></i> Dashboard</span>
      <span *ngSwitchCase="'address-book'"><i class="icomoon-address-book"></i> Address Book</span>
      <span *ngSwitchCase="'login'">Login</span>
      <span *ngSwitchCase="'signup'">Registration</span>
      <span *ngSwitchCase="'kyc'"><i class="icomoon-kys"></i> KYC</span>
      <span *ngSwitchCase="'staking'">Staking pools</span>
      <span *ngSwitchCase="'farming'">Farming pools</span>
      <span class="back-to-prev" *ngSwitchCase="'pool-detail'" (click)="backToPrev()">Pool Details</span>
      <span *ngSwitchCase="'create-pool'">Create Pool</span>

      <span *ngSwitchCase="'claiming'"><i class="icomoon-claim"></i> Claiming Deals</span>
      <a (click)="backToPrev()" class="back-to-prev" *ngSwitchCase="'claiming-deal-detail'">Deal Details</a>

      <span *ngSwitchCase="'deals'">Deals</span>
      <a (click)="backToPrev()" class="back-to-prev" *ngSwitchCase="'deal-detail'">Deal Details</a>

      <span *ngSwitchCase="'portfolio'"><i class="icomoon-portfolio"></i>Portfolio</span>

      <span *ngSwitchCase="'vesting'">
        <span *ngIf="routerPathAddress == vestingSeedAddress">Claim Seed</span>
        <span *ngIf="routerPathAddress == vestingStrategicAddress">Claim Strategic</span>
        <span *ngIf="routerPathAddress == vestingPrivateAddress">Claim Private</span>
        <span *ngIf="routerPathAddress == vestingBountyAddress">Claim Bounty</span>
      </span>
    </h1>
  </div>

  <app-wallet></app-wallet>

  <div class="dashboard-header__select">
    <button mat-button class="dropdown-button __select-network" [matMenuTriggerFor]="networks">
      <img src="{{ web3Service.chainIdNumber | imgNetworkPipe }}"/>
      {{getNetworkName(web3Service.chainIdNumber)}}
    </button>
    <mat-menu #networks="matMenu" class="select-network-menu">
      <button  mat-menu-item *ngFor="let networkId of availableNetworks" (click)="selectNetworkClick(networkId)">
        <img src="{{ networkId | imgNetworkPipe }}"/>
        {{getNetworkName(networkId)}}
      </button>
    </mat-menu>
  </div>
</header>
