<section class="dashboard-section p-40">
  <div class="sign-up-form card" *ngIf="step==1">
    <div class="card-content">
      <div class="subtitle-1 mb-4 text-center">Registration</div>

      <form #signUpForm="ngForm" (ngSubmit)="signUpClick()">
        <mat-form-field appearance="fill" hideRequiredMarker>
          <mat-label>E-mail</mat-label>
          <input matInput
                 type="email"
                 [(ngModel)]="signUpEmail"
                 name="signUpEmail"
                 required
          >
        </mat-form-field>

        <re-captcha class="__captcha my-2" #recaptchaSignUp [(ngModel)]="captchaResponseSignUp" name="captcha" required></re-captcha>

        <button type="submit"
                mat-raised-button
                color="primary"
                class="w-100 mb-12"
                [ngClass]="{'btn-loading': waiting}"
                [disabled]="!signUpForm.form.valid || waiting">
          Next
        </button>
        <a href="https://help.bullperks.com/completing-kyc/" rel="noreferrer" target="_blank" mat-stroked-button class="w-100">
          Prohibited Jurisdictions List
        </a>
      </form>
    </div>
    <div class="card-footer">
      <div class="subtitle-1 d-flex align-items-center mb-3">
        <span class="icon icomoon-warning me-2 mute-40"></span> Warning
      </div>
      <div class="small-text mute-80">
        All our deals are on a First Come First Served (FCFS) basis.
        Guaranteed Allocation is only reserved for Platinum Bulls.
        We do not recommend signing up with Hardware Wallets (Trezor, Ledger, SafePal, e.t.c.) because it is
        difficult to secure allocation in our FCFS deals using these wallets! BullPerks takes no responsibility
        for any penalties incurred due to signing up with a hardware wallet.
      </div>
    </div>
  </div>

  <div class="sign-up-form card" *ngIf="step==2">
    <div class="card-content text-center">
      <div class="subtitle-1 mb-4">Check Your E-mail</div>

      <i class="icon icomoon-mail __icon"></i>

      <div class="small-text mute-80">
        We’ve sent confirmation link on <span class="text-primary">{{signUpEmail}}</span>.<br>
        Follow the link in message to finish registration.
      </div>
    </div>
    <div class="card-footer">
      <form #signUpForm="ngForm" (ngSubmit)="signUpClick()">
        <button type="submit"
                mat-stroked-button
                class="w-100 mb-12" [ngClass]="{'btn-loading': waiting}"
                [disabled]="!signUpForm.form.valid || waiting">
          Resend Verification Email
        </button>
        <re-captcha #recaptchaSignUp [(ngModel)]="captchaResponseSignUp" name="captcha" required></re-captcha>
      </form>
    </div>
  </div>

  <div class="sign-up-form card" *ngIf="step==3">
    <div class="card-content">
      <div class="subtitle-1 mb-4 text-center">Finish Registration</div>

      <form>
        <mat-form-field appearance="fill" hideRequiredMarker>
          <mat-label>E-mail</mat-label>
          <input matInput
                 name="signUpEmail"
                 required
          >
        </mat-form-field>

        <mat-form-field appearance="fill" hideRequiredMarker>
          <mat-label>Name</mat-label>
          <input matInput
                 name="signUpEmail"
                 required
          >
        </mat-form-field>
        <button type="submit" mat-raised-button color="primary" class="w-100 mt-3">Submit</button>
      </form>
    </div>
  </div>

  <div class="sign-up-form card" *ngIf="step==4">
    <div class="card-content text-center">
      <div class="subtitle-1 mb-4">Registration Finished</div>
      <i class="icon icomoon-checked __icon"></i>
      <div class="small-text mute-80 mb-3">
        You successfully registered!
      </div>
      <a href="/" mat-raised-button color="primary" class="w-100">Dashboard</a>
    </div>
  </div>
</section>





