import { Component, Input, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  templateUrl: './dlg-tier-info.component.html',
})

export class DlgTierInfoComponent implements OnInit {
  @Input() tierId: number;
  @Input() tierProgress: number;
  @Input() tierAmount: string;
  @Input() currentAmount: string;
  @Input() leftAmount: string;
  constructor(
    public dialogRef: MatDialogRef<DlgTierInfoComponent>,
  ) {}
  async ngOnInit(): Promise<void> {
  }
}
