<section class="dashboard-section p-40">
  <div class="sign-up-form card" *ngIf="step==1">
    <div class="card-content text-center">
      <div class="subtitle-1 mb-4">Unlock wallet</div>
      <i class="icon icomoon-unlock __icon"></i>
      <button type="button"
              mat-raised-button
              color="primary"
              class="w-100"
              [ngClass]="{'btn-loading': waiting}"
              [disabled]="waiting"
              (click)="unlockWallet()">
        Unlock wallet
      </button>
    </div>
  </div>

  <div class="sign-up-form card" *ngIf="step==2">
    <div class="card-content text-center">
      <div class="subtitle-1 mb-4">Sign message by Metamask and login</div>
      <i class="icon icomoon-unlock __icon"></i>
      <button type="button"
              mat-raised-button
              color="primary"
              class="w-100"
              [ngClass]="{'btn-loading': waiting}"
              [disabled]="waiting"
              (click)="loginClick()">
        Login
      </button>
    </div>
  </div>
</section>
