<main class="dashboard-main">
  <section class="dashboard-section p-40">

    <div class="form-group white-card __token mb-40">
      <div class="white-card__header">
        <label>Token<span class="req">*</span></label>
      </div>
      <div class="white-card__content">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="token-name" placeholder="Token Name">
          <label for="token-name">Token Name</label>
        </div>
        <div class="form-floating">
          <input type="text" class="form-control" id="token-address" placeholder="Token Address">
          <label for="token-address">Token Address</label>
        </div>
      </div>
    </div>

    <div class="row gx-4">
      <div class="col-lg-8 mb-20">
        <div class="form-group white-card">
          <div class="white-card__header">
            <label>Name And Description<span class="req">*</span></label>
          </div>
          <div class="white-card__content">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="deal-name" placeholder="Name">
              <label for="deal-name">Name</label>
            </div>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="deal-link" placeholder="Original Deal Page Link">
              <label for="deal-link">Original Deal Page Link</label>
            </div>

            <div class="form-floating">
              <textarea class="form-control deal-description-textarea" rows="6" id="deal-description" placeholder="Description"></textarea>
              <label for="deal-description">Description</label>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 mb-20">
        <div class="form-group white-card">
          <div class="white-card__header">
            <label>Deal Logo<span class="req">*</span></label>
          </div>
          <div class="white-card__content">
            <div class="upload-control">
              <div class="upload-control__dd">
                <div class="upload-placeholder">
                  <span class="icon icomoon-image"></span>
                  <div class="upload-placeholder__title">JPG, PNG - 100x100 px</div>
                </div>
                <!--<div class="upload-image">
                    <img src="../images/deals/Copper.svg" alt="">
                    <div class="upload-image__title">sienna.jpg - 288 KB</div>
                </div>-->
              </div>
            </div>

            <button class="btn btn-primary w-100 upload-control__btn">Choose a file</button>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="__submit mt-auto bg-white">
    <button class="btn btn-primary btn-icon btn-icon-right w-100" disabled>
      Create Deal
      <i class="icon icomoon-plus"></i>
    </button>
  </section>
</main>
