<section class="dashboard-section p-40">
  <!--usersTgeBalance: number;
          usersTgeReleased: number;
          usersBalance: number;
          usersReleased: number;

          usersReleasableAmount: number;-->
  <div class="row">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-header">
          <div class="subtitle">Vesting Schedule</div>
        </div>
        <div class="card-content p-0">
          <table class="table w-100">
            <tr>
              <td>TGE time</td>
              <td>{{tgeTime* 1000 | date:'dd MMM, yyyy H:mm a'}}</td>
            </tr>
            <tr>
              <td>TGE balance</td>
              <td>{{usersTgeBalance | number: '1.0-4'}} {{rewardTokenSymbol}} </td>
            </tr>
            <tr>
              <td>TGE Released</td>
              <td>{{usersTgeReleased | number: '1.0-4'}} {{rewardTokenSymbol}} </td>
            </tr>
            <tr *ngIf="usersTgeBalance && now >tgeTime">
              <td colspan="2">
                <button mat-raised-button
                        color="primary"
                        class="w-100 btn-icon btn-icon-left"
                        (click)="releaseTGEClick()"
                        [ngClass]="{'btn-loading': waiting}"
                        [disabled]="waiting">
                  <i class="icon icomoon-claim"></i>
                  Claim TGE
                </button>
              </td>
            </tr>
            <tr>
              <td>Vested amount</td>
              <td>{{usersBalance | number: '1.0-4'}} {{rewardTokenSymbol}}</td>
            </tr>
            <tr>
              <td>Released amount</td>
              <td>{{usersReleased | number: '1.0-4'}} {{rewardTokenSymbol}}</td>
            </tr>
            <tr>
              <td>Releasable amount</td>
              <td>{{usersReleasableAmount | number: '1.0-4'}} {{rewardTokenSymbol}}</td>
            </tr>
            <tr *ngIf="usersReleasableAmount">
              <td colspan="2">
                <button mat-raised-button
                        color="primary"
                        class="w-100 btn-icon btn-icon-left"
                        (click)="claimVesingClick()"
                        [ngClass]="{'btn-loading': waiting}"
                        [disabled]="waiting">
                  <i class="icon icomoon-claim"></i>
                  Claim your vesting tokens
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="card">
        <div class="card-header">
          <div class="subtitle">Vesting Information</div>
        </div>
        <div class="card-content p-0">
          <table class="table w-100">
            <tr>
              <td>TGE Vesting %</td>
              <td>{{vestingTGEPercent}}%</td>
            </tr>
            <tr>
              <td>TGE time</td>
              <td>{{tgeTime* 1000 | date:'dd MMM, yyyy H:mm a'}}</td>
            </tr>
            <tr>
              <td>Vesting Start Date</td>
              <td>{{vestingStart* 1000 | date:'dd MMM, yyyy H:mm a'}}</td>
            </tr>
            <tr>
              <td>Vesting Duration</td>
              <td>{{vestingDuration | showPeriod}}</td>
            </tr>
            <tr>
              <td>Vesting Interval</td>
              <td> {{vestingInterval | showPeriod}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
