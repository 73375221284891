import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StakingPoolDTO } from 'src/service-proxies/service-proxies';
import { ComponentBase } from 'src/shared/component-base';
import { Web3Service } from 'src/shared/web3-service';
@Component({
  templateUrl: './dlg-locker-pool.component.html',
})

export class DlgLockerPoolComponent extends ComponentBase implements OnInit {

  // {penalty: days}
  private penaltyTable:any = {
    5: 90,
    10: 60,
    20: 30,
    25: 20,
    30: 10
  }

  constructor(
    private web3Service: Web3Service,
    private _dialog: MatDialog,
  ) {
    super();
   }

  @Input() item: StakingPoolDTO;
  @Input() currentStakeAmount: string;
  @Input() isDepositMode: boolean;
  @Input() account: string;
  @Input() depositAmount: number;

  balance: number;
  withdrawAmount: number;
  waiting: boolean;
  penaltyBP: number;
  penaltyAmount: number;
  staked: boolean;
  stakingTokenDecimals: number;

  isApprovedToken: boolean;

  public penaltyDays: number;
  public userLockingStartTimestamp: number = 0;

  async ngOnInit(): Promise<void> {
    await this.web3Service.initWeb3();
    await this.getBalance();
    this.stakingTokenDecimals = parseInt(await this.web3Service.GetDecimals(this.item.stakingToken));
    this.userLockingStartTimestamp = parseInt(await this.web3Service.getUserLockingStart(this.account));
    if(parseInt(this.currentStakeAmount) > 0) {
      this.staked = true;
      await this.getPenalty();
    }
  }

  async getBalance(): Promise<void> {
    this.web3Service.GetTokenBalance(this.account, this.item.stakingToken).then((balance) => {
     this.balance = this.toNumberFromWei(balance, 18);
    });
  }

  async getPenalty(): Promise<void> {
    this.web3Service.getPoolPenaltyBP(this.account, this.item.poolAddress).then((penalty) => {
      this.penaltyBP = penalty;
      this.penaltyDays = this.penaltyTable[penalty/100];
     });
  }

  public get currentPenalty(): number {
    return this.withdrawAmount * this.penaltyBP / 10000;
  }

  async depositClick(): Promise<void> {
    if(!this.isApprovedToken)
      return;
    this.waiting = true;
    const contractEventsSource = await this.web3Service.depositToPool(this.account, this.depositAmount, this.stakingTokenDecimals, this.item.poolAddress);

    try {
      let response = await contractEventsSource.receipt$.toPromise();
      console.log(response);
      this._dialog.closeAll();
      this.showInfoModal('Confirmed transaction');
    } catch (err) {
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }

  async withdrawClick(): Promise<void> {
    console.log("poolAddress: " + this.item.poolAddress);
    this.waiting = true;
    const contractEventsSource = this.web3Service.withdrawFromPool(this.account, this.withdrawAmount, this.stakingTokenDecimals, this.item.poolAddress);

    try {
      let response = await contractEventsSource.receipt$.toPromise();
      console.log(response);
      this._dialog.closeAll();
      this.showInfoModal('Confirmed transaction');
    } catch (err) {
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }

  async approveClick(): Promise<void> {
    this.waiting = true;

    //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.approve(
      this.account,
      this.item.stakingToken,
      this.item.poolAddress
    );

    try {
      await contractEventsSource.receipt$.toPromise();

      this.isApprovedToken = true;
      this.showInfoModal('Confirmed transaction');
      // this.depositToPoolClick();
    } catch (err) {
      //dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }

  setMaxDepositAmount(): void {
    this.depositAmount = this.balance;
  }

  setMaxWithdrawAmount(): void {
    this.withdrawAmount = parseFloat(this.toNumberFromWei(this.currentStakeAmount, this.stakingTokenDecimals).toString());
  }
}
