
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate, UrlTree } from '@angular/router';
import { UserSessionProvider } from 'src/shared/user-session-provider';
import { menu } from 'src/app/app-aside/menu';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NetworkGuard implements CanActivate {
  constructor(private readonly userSessionProvider: UserSessionProvider, private readonly router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url = `/${route.url.join('/')}`;
    const chainId = this.userSessionProvider.getChainId();
    const ffaMenu = menu.find(i=>i.supportedChains.length == 0 && i.routePath != "/kyc");
    const requestedMenu = menu.find(i=> i.routePath.includes(url) || i.routePath == url);
    if(requestedMenu.supportedChains.includes(chainId)){
      return true
    }else{
      this.router.navigate([ffaMenu.routePath]);
      return false;
    }
  }
}
