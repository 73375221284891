<section class="section-alert" *ngIf="1<0">
  <div class="alert alert-warning" role="alert">
    <div class="alert-icon icomoon-info"></div>
    <div class="alert-heading">
      Warning text. Admins should be able to edit this and turn it on and off.
    </div>
  </div>
</section>

<info-join-deals></info-join-deals>

<section class="dashboard-section p-40">
  <div class="row mb-40 ">
    <div class="col-lg-5 mb-4 mb-lg-0">
      <div class="card p-4 mb-20">
        <div class="subtitle-1 mb-4 d-flex align-items-center">
          <i class="icon icomoon-wallet me-12 text-muted"></i>
          Wallet
        </div>
        <div class="h5 mb-4">{{userWalletBalance| shiftDecimals:-18| number: '1.0-2'}} BLP</div>

        <button mat-stroked-button class="w-100 mb-2" (click)="lockTokenClick(true)">
          Lock-In Tokens
        </button>

        <a href="https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0xfe1d7f7a8f0bda6e415593a2e4f82c64b446d404"
           target="_blank"
           mat-raised-button
           color="primary"
           class="w-100">
          Buy BLP
        </a>
      </div>

      <div class="card p-4">
        <div class="subtitle-1 mb-4 d-flex align-items-center">
          <i class="icon icomoon-lock me-12 text-muted"></i>
          Locked-In Tokens
        </div>
        <div class="h5 mb-4">{{lockerUserBalance| shiftDecimals:-18| number: '1.0-2'}} BLP</div>

        <div class="__unlock-group mb-4 d-flex align-items-center">
          <button mat-stroked-button
                  class="__unlock-tokens"
                  (click)="lockTokenClick(false)">
            Unlock Tokens
          </button>
          <button mat-icon-button class="__unlock-info" (click)="openUnlockInfoDlg()">
            <i class="icomoon-info"></i>
          </button>
        </div>
        <div class="__unlock-date d-lg-flex align-items-center justify-content-between text-muted">
          <div>Locking Start Date</div>
          <div>{{userLockingStartTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}</div>
        </div>
        <div class="__unlock-date d-lg-flex align-items-center justify-content-between text-muted" *ngIf="0<needToWaitTime">
          <div>Time Lockup Ends</div>
          <div>{{needToWaitTime|showPeriod}}</div>
        </div>
      </div>

      <div class="card p-4 mt-4" *ngIf="isLockedBalanceExist">
        <div class="subtitle-1 mb-4 d-flex align-items-center">
          <i class="icon icomoon-wallet me-12 text-muted"></i>
          Old BLP Tokens
        </div>
        <div class="h5 mb-4">{{lockerOldUserBalance| shiftDecimals:-18| number: '1.0-20'}} BLP</div>

        <button mat-stroked-button
                class="__unlock-tokens"
                (click)="lockTokenOldClick(false)">
          Unlock Old Tokens
        </button>
      </div>
    </div>

    <div class="col-lg-7">
      <div class="card min-h-100">
        <div class="card-header">
          <div class="subtitle-1 d-flex align-items-center">
            <i class="icon icomoon-chart-pie me-12 text-muted"></i>
            Summary
          </div>
        </div>
        <div class="card-content">
          <div class="caption text-muted mb-1">Your Tier</div>
          <div class="__current-tier">
            <img [src]="userTierIndex | imgTierPipe" alt="" />
            <span class="subtitle-1">{{userTierIndex|nameTierPipe}}</span>
          </div>

          <div class="progress-group mb-1">
            <div class="progress-legend">
              <div class="progress-legend__value">
                <span *ngIf="!isMaxTier">{{nextTierNeedValue | shiftDecimals:-18| number: '1.0-2'}} BLP for next tier</span>
              </div>
            </div>
            <div class="progress progress-xs">
              <div class="progress-bar"
                   role="progressbar"
                   [style.width.%]="currentTierProgress">
              </div>
            </div>
            <div class="progress-legend">
              <div class="progress-legend__percent">{{lockerUserBalance| shiftDecimals:-18| number: '1.0-2'}} BLP</div>
              <div class="progress-legend__value">{{ nextTierValue | shiftDecimals:-18| number: '1.0-2'}} BLP</div>
            </div>
          </div>
        </div>
        <div class="card-footer">

          <div class="__graph pt-3">
            <div class="__graph__donut d-none d-lg-block">
              <chart *ngIf="isAuthorized && chartData" type="doughnut" [data]="chartData" [options]="chartOptions"></chart>
              <div class="dm-donut --placeholder" *ngIf="!isAuthorized || !chartData"></div>
            </div>
            <div class="__graph__legend">
              <div>
                <div class="legend-item">
                  <span class="legend-item__point" style="background:#131BCB"></span>
                  <span class="legend-item__title"><a routerLink="staking">Staking {{stakingPercent}}%</a></span>
                  <span class="legend-item__blp">{{stakingUserBalance | shiftDecimals:-18| number: '1.0-2'}} BLP</span>
                </div>
                <!-- <div class="legend-item">
                  <span class="legend-item__point" style="background:#AB57FF"></span>
                  <span class="legend-item__title"><a routerLink="farming">Farming 0%</a></span>
                  <span class="legend-item__blp">0 BLP</span>
                </div> -->
                <div class="legend-item">
                  <span class="legend-item__point" style="background:#31C2B9"></span>
                  <span class="legend-item__title">Locked-In {{lockedBalancePercent}}%</span>
                  <span class="legend-item__blp">{{lockerUserBalance| shiftDecimals:-18| number: '1.0-2'}} BLP</span>
                </div>
              </div>
              <div class="legend-item">
                <span class="legend-item__title">Total</span>
                <span class="legend-item__blp">{{totalUserBlpBalance| shiftDecimals:-18| number: '1.0-2'}} BLP</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 mb-4 mb-lg-0 order-lg-2">
      <div class="card min-h-100">
        <div class="card-header">
          <div class="subtitle-1 d-flex align-items-center">
            <i class="icon icomoon-chart me-12 text-muted"></i>
            Tier Progress
          </div>
        </div>
        <div class="__tiers-list">
          <div class="__tiers-list-item" *ngFor="let currentTier of allTiers; index as i">
            <div class="__tier-card"
                 [ngClass]="{'closed': !isAuthorized || i > nextTierIndex}"
                 (click)="showTierInfoDlg(i)"
                 matTooltipClass="tooltip-md"
                 matTooltipPosition="above"
                 matTooltip="{{i|nameTierPipe}} details"
              >
              <div class="__tier-card-logo">
                <img [src]="i | imgTierPipe" alt="" />
              </div>
              <div class="__tier-card-name">{{i|nameTierPipe}}</div>
              <div class="__tier-card-progress">
                <div class="progress progress-xs">
                  <div class="progress-bar"
                       role="progressbar"
                       [style.width.%]="getTierPercent(i, currentTier.blpAmount)"></div>
                </div>
              </div>
              <div class="__tier-card-value">
                {{currentTier.blpAmount| shiftDecimals:-18}} BLP
              </div>
              <div class="__tier-card-checked" *ngIf="i <= userTierIndex">
                <i class="icon icon-xs icomoon-checked text-primary"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 order-lg-1">
      <div class="card min-h-100">
        <div class="card-header d-flex align-items-center">
          <i class="icon icomoon-chart-down me-12 text-muted"></i>
          <div class="subtitle-1">Penalties</div>

          <div class="info-tooltip icomoon-info ms-auto"
               matTooltipClass="tooltip-md"
               matTooltipPosition="above"
               matTooltip="There are penalties when you unlock, based on the date you deposited your last tokens"></div>
        </div>
        <div class="card-content p-0">
          <table class="table penalties-table">
            <tr *ngFor="let currentPenalty of allPenalties; index as i;">
              <th>Less than {{currentPenalty.duration|showPeriod}}</th>
              <td>{{currentPenalty.penaltyBP/100}}%</td>
            </tr>
            <tr *ngIf="allPenalties.length==5">
              <th>After {{allPenalties[4].duration|showPeriod}}</th>
              <td>0%</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>

