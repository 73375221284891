import { Component, OnInit } from '@angular/core';
import { DlgAddressBookComponent } from '../dlg-address-book';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { ComponentBase } from '../../shared/component-base';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { Router } from '@angular/router';
import {
  AddressBookChangeRequestStatus,
  AddressBookEntryDTO,
  AddressBookRequestFullDTO,
  AddressBookServiceProxy
} from '../../service-proxies/service-proxies';
import { environment } from '../../environments/environment';

@Component({
  selector: 'address-book',
  templateUrl: './address-book.component.html',
  styleUrls: ['./address-book.component.scss'],
})
export class AddressBookComponent extends ComponentBase implements OnInit {
  constructor(
    private _dialog: MatDialog,
    private userSessionProvider: UserSessionProvider,
    private router: Router,
    private addressBookServiceProxy: AddressBookServiceProxy
  )
  {
    super();
  }
  account: string = '';
  public dbAddresses: Array<AddressBookEntryDTO> = [];
  public dbAddressChangeRequests: Array<AddressBookRequestFullDTO> = [];
  public allAddresses: Array<AddressBookEntryDTO> = [];
  public get enumStatus(): typeof AddressBookChangeRequestStatus {
    return AddressBookChangeRequestStatus;
  }

  public allAvailableNetworks = environment.bsc.networkTypes;

  async ngOnInit() {
    console.log('linkedWallet');
    if (this.userSessionProvider.authToken) {
      this.eventLogin(this.userSessionProvider.linkedWallet);
    }
    else {
      console.error('Not Auth. redirect to login page');
      this.router.navigate(["/login"]);
    }
  }

  async eventLogin(username: string) {
    console.log('eventLogin - '+ username);
    if (this.account != username) {
      this.account = username;

      this.addressBookServiceProxy.getAllByUserWallet(this.userSessionProvider.linkedWallet)
        .subscribe(result => {
            this.dbAddresses = result;
            this.filterList();
          },
          error => {
            console.error(error);
          });

      this.addressBookServiceProxy.getChangeRequestsByUserWallet(this.userSessionProvider.linkedWallet)
        .subscribe(result => {
            this.dbAddressChangeRequests = result;
          },
          error => {
            console.error(error);
          });
    }
  }

  filterList() {
    for (const network of this.allAvailableNetworks) {
        let dto = this.dbAddresses.find(p => p.networkId == network.id);
        if (!dto)
        {
          dto = new AddressBookEntryDTO();
          dto.networkId = network.id;
          dto.userWallet = this.account;
        }
        this.allAddresses.push(dto);
    }
  }

  public openEditAddressDlg(dto: AddressBookEntryDTO) {
    const dialogRef = this._dialog.open(DlgAddressBookComponent, {
      panelClass: ['dlg-light', 'dlg-medium'],
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialogRef.disableClose = true;
    dialogRef.componentInstance.actionAddressMode = 'edit';
    dialogRef.componentInstance.dto = dto;
  }

  public openRemoveAddressDlg(dto: AddressBookEntryDTO) {
    const dialogRef = this._dialog.open(DlgAddressBookComponent, {
      panelClass: ['dlg-light', 'dlg-medium'],
      scrollStrategy: new NoopScrollStrategy(),
      autoFocus: false,
    });
    dialogRef.disableClose = true;
    dialogRef.componentInstance.actionAddressMode = 'remove';
    dialogRef.componentInstance.dto = dto;
  }

  public openAddAddressDlg(dto: AddressBookEntryDTO) {
    const dialogRef = this._dialog.open(DlgAddressBookComponent, {
      panelClass: ['dlg-light', 'dlg-medium'],
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialogRef.disableClose = true;
    dialogRef.componentInstance.actionAddressMode = 'add';
    dialogRef.componentInstance.dto = dto;
  }

  public getEnumValue(status : AddressBookChangeRequestStatus):string{
    return AddressBookChangeRequestStatus[status];
}
}
