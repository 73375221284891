import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ComponentBase } from '../../shared/component-base';
import { EventBus } from '../../shared/event-bus';
import { Web3Service } from '../../shared/web3-service';
import BigNumber from 'bignumber.js';
import { DlgContractService } from '../dlg-contract.service';

import { AlertService } from '../shared-dlg.module';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AddressBookEntryDTO,
  AddressBookServiceProxy,
  DealDTO,
  DealRegistrationDTO,
  DealServiceProxy,
  UserDTO,
  UsersServiceProxy
} from '../../service-proxies/service-proxies';
import { NetworkNamePipe } from '../../shared/pipes/networkName.pipe';
import { DlgAddressBookComponent } from '../dlg-address-book';

@Component({
  selector: 'deal-detail',
  templateUrl: './deal-detail.component.html',
  styleUrls: ['./deal-detail.component.scss'],
})
export class DealDetailComponent extends ComponentBase implements OnInit, OnDestroy {
  constructor(
    private _dialog: MatDialog,
    private _dlgContractSrv: DlgContractService,
    private _alertSrv: AlertService,
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private route: ActivatedRoute,
    private router: Router,
    private dealService: DealServiceProxy,
    private usersService: UsersServiceProxy,
    private addressBookServiceProxy: AddressBookServiceProxy
  ) {
    super();

    this.now = Math.floor(Date.now() / 1000);

    this.updateTimeTimerId = setInterval(() => {
      this.now = Math.floor(Date.now() / 1000);

      let diffStart = 0;
      if (this.isUpcoming) {
        diffStart = this.startTimestamp - this.now;
      }
      else if (this.isStarted) {
        diffStart = this.finishTimestamp - this.now;
      }
      if (diffStart > 0) {
        this.timerViewDays = Math.floor(diffStart / (3600 * 24));
        this.timerViewHours = Math.floor(diffStart % (3600 * 24) / 3600);
        this.timerViewMin = Math.floor(diffStart % 3600 / 60);
        this.timerViewSec = Math.floor(diffStart % 60);
      }
      else {
        this.timerViewDays = 0;
        this.timerViewHours = 0;
        this.timerViewMin = 0;
        this.timerViewSec = 0;
      }
    }, 1000);
  }

  userInWhitelist: boolean = true;
  isCollectWalletDeal: boolean = false;
  emissionAddress: string;

  deal: DealDTO;
  userInfo: UserDTO;
  dealRegistration: DealRegistrationDTO;
  addressBookEntry: AddressBookEntryDTO;

  kycConfirmed: boolean = false;
  isAuthorized: boolean = false;

  waiting: boolean = false;
  account: string = '';
  dealAddress: string = '';

  //#region contract data
  public isApprovedPaymentToken: boolean = false;
  paymentToken: string;

  tokenPrice: string;
  rewardToken: string;
  rewardDecimals: number;
  startTimestamp: number;
  finishTimestamp: number;
  startClaimTimestamp: number;
  maxDistributedTokenAmount: string;
  totalRaise: string; // Sum of all payments (in payment token)
  tokensForDistribution: string;
  leftTokensForDistribution: string;
  minimumRaise: string;
  distributedTokens: string = '0';
  allowRefund: boolean;

  vestingContractAddress: string;
  vestingPercent: number;
  vestingStart: number;
  vestingInterval: number;
  vestingDuration: number;

  //#end region contract data
  paymentDecimal: number;
  paymentTokenSymbol: string;
  rewardTokenSymbol: string;
  tokensPerETH: number;
  targetNetwork: number;

  userTier: any;
  userTierIndex: number = -1;
  allTiers: any[] = new Array();


  updateTimeTimerId: NodeJS.Timeout;
  updateUserTimerId: NodeJS.Timeout;
  updateDealTimerId: NodeJS.Timeout;

  now: number;

  timerViewDays: number;
  timerViewHours: number;
  timerViewMin: number;
  timerViewSec: number;

  //Last participation time
  lastParticipationTime: number = 0;
  lockupDuration: number = 0;
  public get needToWaitTime(): number {
    let needTiWait = this.lastParticipationTime + this.lockupDuration - this.now;
    if (needTiWait > 0)
      return needTiWait;
    else
      return 0;
  }

  usersTicketSize: number = 0;

  get usersTicketSizeInRewardTokens() {
    return this.usersTicketSize * this.tokensPerETH;
  }
  set usersTicketSizeInRewardTokens(value) {
    this.usersTicketSize = (value / this.tokensPerETH);
  }
  get userTierMaxTokens() {
    if (this.userTier?.ticketSize) {
      return new BigNumber(this.userTier.ticketSize).shiftedBy(this.paymentDecimal).dividedBy(this.tokenPrice).shiftedBy(-this.paymentDecimal).toNumber();
    }
    return 0;
  }

  usersDebtAmount: number;
  usersTotalPaymentAmount: number;
  usersClaimedTokensAmount: number;
  usersReleasableAmount: number = 0;
  usersVestedAmount: number = 0;
  usersVestedReleasedAmount: number = 0;


  public get allowClaim(): boolean {
    if (!this.startClaimTimestamp || this.startClaimTimestamp == 0) {
      return false;
    }
    return this.now > this.startClaimTimestamp;
  }

  public get isUpcoming(): boolean {
    return this.now < this.startTimestamp;
  }

  public get isStarted(): boolean {
    return this.now > this.startTimestamp && this.now < this.finishTimestamp;
  }

  public get isFinished(): boolean {
    return this.now > this.finishTimestamp;
  }

  public get allowPay(): boolean {
    if (!this.startTimestamp) {
      return false;
    }
    if (!this.finishTimestamp) {
      return false;
    }

    return this.now > this.startTimestamp && this.now < this.finishTimestamp;
  }

  public get canPayMore(): boolean {
    return new BigNumber(this.usersTotalPaymentAmount).toNumber() < new BigNumber(this.userTier?.ticketSize).shiftedBy(-18).toNumber();
  }

  public get getDistributedPercent(): number {
    if (this.maxDistributedTokenAmount && this.tokensForDistribution) {
      return new BigNumber(this.tokensForDistribution).div(this.maxDistributedTokenAmount).multipliedBy(100).toNumber();
    }
    return 0;
  }

  public getDistributedPercentTier(maxDistributedTokenAmountTier: number, tokensForDistributionTier: number): number {
    if (maxDistributedTokenAmountTier && tokensForDistributionTier) {
      return new BigNumber(tokensForDistributionTier).div(maxDistributedTokenAmountTier).multipliedBy(100).toNumber();
    }
    return 0;
  }

  public get getDistributedLeft(): string {
    if (this.maxDistributedTokenAmount && this.tokensForDistribution) {
      return new BigNumber(this.maxDistributedTokenAmount).minus(this.tokensForDistribution).toString();
    } else {
      return '0';
    }
  }

  public getTotalRaiseETH(deal: DealDTO): number {
    return new BigNumber(deal.maxDistributedTokenAmount).shiftedBy(-deal.rewardDecimal).multipliedBy(deal.tokenPrice).shiftedBy(-deal.paymentDecimal).toNumber();
  }

  async ngOnInit() {
    this.isAuthorized = this.userSessionProvider.isAuthorized;

    this.route
      .queryParams
      .subscribe(params => {
        this.dealAddress = params['address'];
        console.log(`deal address: ${this.dealAddress}`);
        this.dealService.getByAddress(this.dealAddress).subscribe(result => {
          this.deal = result;
          this.rewardTokenSymbol = this.deal.rewardTokenSymbol;
          this.isCollectWalletDeal = this.deal.isDealWithoutDistribution;
          this.paymentTokenSymbol = this.deal.paymentTokenSymbol;
          this.targetNetwork = this.deal.targetNetwork;

          console.log('account=' + this.account);
          console.log('targetNetwork=' + this.targetNetwork);
          this.addressBookServiceProxy.getByUserWalletAndChainId(this.account, this.targetNetwork)
            .subscribe(result => {
              this.addressBookEntry = result;
              this.emissionAddress = this.addressBookEntry?.emissionAddress;
            },
              error => {
                console.error(error);
              });
          console.log(this.deal);
          this.checkWhiteList();
          if (this.web3Service.chainIdNumber != this.deal.chainId) {
            const chainName = new NetworkNamePipe().transform(this.deal.chainId);
            this.showErrorModal(`Select ${chainName} network in metamask!`);
            this.router.navigate(['/deals']);
          }
        },
          error => {
            console.error(error);
          });
      });

    if (this.userSessionProvider.isAuthorized) {
      this.usersService.getMe()
        .subscribe(
          result => {
            this.userInfo = result;
            this.kycConfirmed = result.kycConfirmed;
          },
          error => {
            this.processServiceError(error);
          }
        );

      this.dealService.getRegistration(this.dealAddress)
        .subscribe(
          result => {
            this.dealRegistration = result;
          },
          error => {
            this.processServiceError(error);
          }
        );
    }

    if (this.userSessionProvider.linkedWallet) {
      this.eventLogin(this.userSessionProvider.linkedWallet);
    }

    await this.web3Service.initWeb3();
    if (this.web3Service.web3) {
      this.updateDealContractData();

      this.updateDealDataInterval();
      this.updateDealTimerId = setInterval(() => {
        this.updateDealDataInterval();
      }, this.expectedBlockTime);
    }

    this.eventBus.loginEvent.subscribe(result => {
      console.log('loginEvent subscription:' + result);
      this.eventLogin(result);
    });

    this.eventBus.logoutEvent.subscribe(result => {
      console.log('logoutEvent subscription:' + result);
      this.eventLogout();
    });
  }

  checkWhiteList(): void {
    if (this.deal.enabledWhitelisting && this.userSessionProvider.isAuthorized) {
      //Check if user in white list
      this.dealService.checkWhiteList(this.dealAddress).subscribe(result => {
        this.userInWhitelist = result.value;
      },
        error => {
          console.error(error);
        });
    }
  }

  async eventLogin(username: string) {
    console.log('eventLogin');
    console.log(username);
    if (this.account != username) {
      this.account = username;

      //TODO:HACK call early than updateDealContractData
      await this.setRewardDecimals();
      this.paymentToken = await this.web3Service.getDealPaymentToken(this.dealAddress);
      if (this.paymentToken != "0x0000000000000000000000000000000000000000") {
        this.paymentDecimal = parseInt(await this.web3Service.GetDecimals(this.paymentToken));
      }
      else {
        this.paymentDecimal = 18;
        this.isApprovedPaymentToken = true;
      }

      this.updateUserData();

      this.updateUserTimerId = setInterval(() => {
        this.updateUserData();
      }, this.expectedBlockTime);

      //this.getTokensDebts(username);
    }
  }

  eventLogout(): void {
    console.log('signOut')
    this.account = "";

    //this.tokenDebt = 0;
    //this.totalInvestedETH = 0;
    //this.ethBalance = 0;
    //this.totalStakeETH = null;
    //this.totalBuyToken = null;
    //this.myClaimedTokensAmount = null;
    //this.tokensDebt = new Array();

    if (this.updateUserTimerId) {
      clearInterval(this.updateUserTimerId);
    }
  }

  async payClick() {
    if (!this.dealRegistration) {
      this.showWarningModal("Click Join Deal");
      return;
    }
    if (!this.userInWhitelist) {
      this.showWarningModal("You are not in Whitelist");
      return;
    }
    if (this.needToWaitTime > 0) {
      this.showWarningModal("You need to wait lockup period");
    }
    this.waiting = true;

    //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();
    let ticketSizeString = "0x" + new BigNumber(this.usersTicketSize).shiftedBy(this.paymentDecimal).toString(16);
    const contractEventsSource = this.web3Service.dealPay(this.dealAddress, this.account, ticketSizeString,
      this.dealRegistration.signature, this.deal.paymentToken == '0x0000000000000000000000000000000000000000');

    contractEventsSource.transactionHash$
      .subscribe(val => {
        this.waiting = false;
        this._dialog.closeAll();
        console.log(`transactionHash$ ${val}`);
        //this._dlgContractSrv.showSubmitted({ tx: val });
        this._alertSrv.show('Transaction submitted.');
      });

    try {
      await contractEventsSource.receipt$.toPromise();

      //dialogRef.close();
      this._alertSrv.show('Confirmed transaction');
      this.updateUserData();
    } catch (err) {
      //dialogRef.close();
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }

  async payClickWithWalletAddress() {
    if (!this.dealRegistration) {
      this.showWarningModal("Click Join Deal");
      return;
    }
    if (!this.userInWhitelist) {
      this.showWarningModal("You are not in Whitelist");
      return;
    }
    if (!this.emissionAddress) {
      this.showWarningModal("Need to set emission address");
      return;
    }
    if (this.needToWaitTime > 0) {
      this.showWarningModal("You need to wait lockup period");
    }
    this.waiting = true;


    //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();
    let ticketSizeString = "0x" + new BigNumber(this.usersTicketSize).shiftedBy(this.paymentDecimal).toString(16);
    const contractEventsSource = this.web3Service.dealPayWithEmissionAddress(this.dealAddress, this.account,
      this.emissionAddress, ticketSizeString,
      this.dealRegistration.signature, this.deal.paymentToken == '0x0000000000000000000000000000000000000000');

    contractEventsSource.transactionHash$
      .subscribe(val => {
        this.waiting = false;
        this._dialog.closeAll();
        console.log(`transactionHash$ ${val}`);
        //this._dlgContractSrv.showSubmitted({ tx: val });
        this._alertSrv.show('Transaction submitted.');
      });

    try {
      await contractEventsSource.receipt$.toPromise();

      //dialogRef.close();
      this._alertSrv.show('Confirmed transaction');
      this.updateUserData();
    } catch (err) {
      //dialogRef.close();
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }


  async claimClick() {
    this.waiting = true;

    //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.dealClaim(this.dealAddress, this.account);

    contractEventsSource.transactionHash$
      .subscribe(val => {
        this.waiting = false;
        this._dialog.closeAll();
        console.log(`transactionHash$ ${val}`);
        //this._dlgContractSrv.showSubmitted({ tx: val });
        this._alertSrv.show('Transaction submitted.');
      });

    try {
      await contractEventsSource.receipt$.toPromise();

      //dialogRef.close();
      this._alertSrv.show('Confirmed transaction');
      this.updateUserData();
    } catch (err) {
      //dialogRef.close();
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }


  async claimVesingClick() {
    this.waiting = true;

    //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.vestingRelease(this.vestingContractAddress, this.account);

    contractEventsSource.transactionHash$
      .subscribe(val => {
        this.waiting = false;
        this._dialog.closeAll();
        console.log(`transactionHash$ ${val}`);
        //this._dlgContractSrv.showSubmitted({ tx: val });
        this._alertSrv.show('Transaction submitted.');
      });

    try {
      await contractEventsSource.receipt$.toPromise();

      //dialogRef.close();
      this._alertSrv.show('Confirmed transaction');
      this.updateUserData();
    } catch (err) {
      //dialogRef.close();
      console.info('catch');
      console.info(err);
    }
    this.waiting = false;
  }

  async updateDealContractData() {

    console.log('Get contract data')
    this.paymentToken = await this.web3Service.getDealPaymentToken(this.dealAddress);
    if (this.paymentToken != "0x0000000000000000000000000000000000000000") {
      this.web3Service.GetContractSymbol(this.paymentToken).then((resp) => {
        this.paymentTokenSymbol = resp;
      });
      this.paymentDecimal = parseInt(await this.web3Service.GetDecimals(this.paymentToken));
    }
    else {
      //this.paymentTokenSymbol = get from DTO
      this.paymentDecimal = 18;
      this.isApprovedPaymentToken = true;
    }
    this.tokenPrice = await this.web3Service.getDealTokenPrice(this.dealAddress);
    this.rewardToken = await this.web3Service.getDealRewardToken(this.dealAddress);

    //TODO: if another chain reward token will be empty
    if (this.rewardToken) {
      this.web3Service.GetContractSymbol(this.rewardToken).then((resp) => {
        this.rewardTokenSymbol = resp;
      });
    }

    await this.setRewardDecimals();

    this.tokensPerETH = new BigNumber(1).shiftedBy(this.paymentDecimal).dividedBy(this.tokenPrice).toNumber();

    this.startTimestamp = parseInt(await this.web3Service.getDealStartTimestamp(this.dealAddress));
    this.finishTimestamp = parseInt(await this.web3Service.getDealFinishTimestamp(this.dealAddress));
    this.startClaimTimestamp = parseInt(await this.web3Service.getDealStartClaimTimestamp(this.dealAddress));


    this.web3Service.getDealMinimumRaise(this.dealAddress).then((resp) => {
      this.minimumRaise = resp;
    });

    this.web3Service.getDealAllowRefund(this.dealAddress).then((resp) => {
      this.allowRefund = Boolean(resp);
    });


    const tierLength = parseInt(await this.web3Service.getDealTiersLength(this.dealAddress));
    for (let i = 0; i < tierLength; i++) {
      let tier = await this.web3Service.getDealTiers(this.dealAddress, i);
      console.log(tier);
      this.allTiers.push(tier);
    }

    //New way to calculate total from sum of all tiers
    let totalTokens = new BigNumber(0);
    for (let i = 0; i < this.allTiers.length; i++) {
      totalTokens = totalTokens.plus(new BigNumber(this.allTiers[i].allocation));
    }
    this.maxDistributedTokenAmount = totalTokens.toString();

    this.web3Service.getVestingPercent(this.dealAddress).then((resp) => {
      this.vestingPercent = parseInt(resp);
    });

    let vestAddress = await this.web3Service.getDealVestingAddress(this.dealAddress);
    this.vestingContractAddress = vestAddress == "0x0000000000000000000000000000000000000000" ? null : vestAddress;

    if (this.vestingContractAddress) {

      this.web3Service.getVVestingStart(this.vestingContractAddress).then((resp) => {
        this.vestingStart = parseInt(resp);
      });

      this.web3Service.getVVestingInterval(this.vestingContractAddress).then((resp) => {
        this.vestingInterval = parseInt(resp);
      });

      this.web3Service.getVVestingDuration(this.vestingContractAddress).then((resp) => {
        this.vestingDuration = parseInt(resp);
      });
    }
  }


  updateAllowancePaymentToken(ticketSize: string): void {
    if (this.paymentToken != '0x0000000000000000000000000000000000000000') {
      //Проверяем разрешение тратить pool token в размере tokenSupply
      this.web3Service.GetAllowance(this.account, this.paymentToken, this.dealAddress).then((resp) => {
        console.log(`GetAllowance paymentToken ${resp}`);
        if (new BigNumber(resp).gte(new BigNumber(ticketSize))) {
          this.isApprovedPaymentToken = true;
        }
        else {
          this.isApprovedPaymentToken = false;
        }
      });
    }
  }


  async approvePaymentClick(): Promise<void> {
    this.waiting = true;

    //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.approve(
      this.account,
      this.paymentToken,
      this.dealAddress
    );

    //contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    try {
      await contractEventsSource.receipt$.toPromise();

      //dialogRef.close();
      //this._alertSrv.show('Confirmed transaction');
      this._alertSrv.show('Confirmed transaction');
      this.updateAllowancePaymentToken(this.userTier.ticketSize);
    } catch (err) {
      //dialogRef.close();
      console.info('catch');
      console.info(err);
    }

    this.waiting = false;
  }


  async updateDealDataInterval() {
    this.web3Service.getDealTokensForDistribution(this.dealAddress).then((resp) => {
      this.tokensForDistribution = resp;
    });

    this.web3Service.getDealTotalRaise(this.dealAddress).then((resp) => {
      this.totalRaise = resp;
    });

    this.web3Service.getDealDistributedTokens(this.dealAddress).then((resp) => {
      this.distributedTokens = resp;
    });
  }

  async setRewardDecimals() {
    //Check if deal without distribution
    let rewardTokenAddress = await this.web3Service.getDealRewardToken(this.dealAddress)
    if (!rewardTokenAddress) {
      this.rewardDecimals = 18;
    }
    else {
      this.rewardDecimals = parseInt(await this.web3Service.getDealDecimals(this.dealAddress));
    }
  }

  async updateUserData() {
    console.log('updateUserData');
    await this.setRewardDecimals();

    this.web3Service.getDealUserInfo(this.dealAddress, this.account).then((userInfo) => {
      console.log(userInfo);
      console.log('userInfo');
      this.usersDebtAmount = this.toNumberFromWei(userInfo.debt, this.rewardDecimals);
      this.usersTotalPaymentAmount = this.toNumberFromWei(userInfo.totalPayment, this.paymentDecimal);
      this.usersClaimedTokensAmount = this.toNumberFromWei((new BigNumber(userInfo.total)).minus(userInfo.debt).toString(), this.rewardDecimals);
    });

    this.web3Service.getLastParticipations(this.account).then((resp) => {
      this.lastParticipationTime = parseInt(resp);
    });

    let vestAddress = await this.web3Service.getDealVestingAddress(this.dealAddress);
    this.vestingContractAddress = vestAddress == "0x0000000000000000000000000000000000000000" ? null : vestAddress;

    if (this.vestingContractAddress) {
      this.web3Service.getVestingReleasableAmount(this.vestingContractAddress, this.account).then((resp) => {
        this.usersReleasableAmount = this.toNumberFromWei(resp, this.rewardDecimals);
      });

      this.web3Service.getVestingForUser(this.vestingContractAddress, this.account).then((resp) => {
        this.usersVestedAmount = this.toNumberFromWei(resp[0], this.rewardDecimals);
        this.usersVestedReleasedAmount = this.toNumberFromWei(resp[1], this.rewardDecimals);
      });
    }

    this.web3Service.getDealUsersTierIndex(this.dealAddress, this.account).then(async (resp) => {
      //TODO: check response success
      console.log('getDealUsersTierIndex');
      console.log(resp);
      if (Boolean(resp[0])) {
        this.userTierIndex = parseInt(resp[1])
        this.userTier = await this.web3Service.getDealTiers(this.dealAddress, this.userTierIndex);
        if (this.usersTicketSize == 0) {
          this.usersTicketSize = parseFloat((this.toNumberFromWei(this.userTier.ticketSize, this.paymentDecimal) - this.usersTotalPaymentAmount).toFixed(2));
        }
        console.log('userTier');
        console.log(this.userTier);
        this.updateAllowancePaymentToken(this.userTier.ticketSize);

        this.web3Service.getDealLockupsTiers(this.userTierIndex).then(async (resp) => {
          this.lockupDuration = parseInt(resp[1]);
        });
      }
      else {
        console.error(resp);
        console.error(`Tier not found for user: ${this.account}`)
      }
    });
  }


  joinDealClick() {
    if (this.userSessionProvider.isAuthorized) {
      if (this.kycConfirmed) {
        this.dealService.registrate(this.dealAddress).subscribe(result => {
          this.dealRegistration = result;
          this.showSuccessModal("You are joined");
        },
          error => {
            this.processServiceError(error)
          });
      }
      else {
        this.navigateToKYC();
      }
    }
    else {
      this.navigateToLogin();
    }
  }

  processServiceError(error: any) {
    if (error.status == 401) {
      console.error('401');
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    }
    else
      this._alertSrv.show(JSON.parse(error.response).message, 'error');
  }

  navigateToLogin(): void {
    this.router.navigate(["/login"]);
  }

  navigateToKYC(): void {
    this.router.navigate(["/kyc"]);
  }
  addToGoogleCalendar() {
    const startDate = new Date(this.deal.startTime * 1000).toISOString().replace(/-|:|\.\d\d\d/g, '');
    const finishDate = new Date(this.deal.finishTime * 1000).toISOString().replace(/-|:|\.\d\d\d/g, '');
    const url = new URL('https://www.google.com/calendar/render');
    const eventInfo = 'Deal details: <a href="https://app.bullperks.com/deal-detail?address=' + this.deal.dealAddress + '">' +
      'https://app.bullperks.com/deal-detail?address=' + this.deal.dealAddress + '</a>';
    url.searchParams.append('action', 'TEMPLATE');
    url.searchParams.append('text', this.deal.name + ' Deal on BullPerks');
    url.searchParams.append('dates', startDate + '/' + finishDate);
    url.searchParams.append('details', eventInfo);
    url.searchParams.append('sf', 'true');
    url.searchParams.append('output', 'xml');

    window.open(url.toString(), '_blank').focus();
  }

  async ngOnDestroy() {
    if (this.updateUserTimerId) {
      clearInterval(this.updateUserTimerId);
    }
    if (this.updateDealTimerId) {
      clearInterval(this.updateDealTimerId);
    }
    if (this.updateTimeTimerId) {
      clearInterval(this.updateTimeTimerId);
    }
  }

  scrollToDescription() {
    document.getElementById('deal-about').scrollIntoView();
  }

  public openAddAddressDlg() {
    const dialogRef = this._dialog.open(DlgAddressBookComponent, {
      panelClass: ['dlg-light', 'dlg-medium'],
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialogRef.disableClose = true;
    dialogRef.componentInstance.actionAddressMode = 'add';
    let dto = new AddressBookEntryDTO();
    dto.networkId = this.deal.targetNetwork;
    dto.userWallet = this.account;
    dialogRef.componentInstance.dto = dto;
  }
}
