<header class="mob-menu-header bg-dark">
  <button
    class="mob-menu-toggle"
    type="button"
    (click)="toggleMenu()">
    <b></b>
  </button>

  <div class="mob-menu-header__title" [ngSwitch]="routerPath">
    <span *ngIf="routerPath=='/'">Home</span>
    <span *ngIf="routerPath=='/address-book'">Address Book</span>
    <span *ngIf="routerPath=='/kyc'">KYC</span>
    <span *ngIf="routerPath=='/deals'">Deals</span>
    <span *ngIf="routerPath=='/deal-detail'">Deal Details</span>
    <span *ngIf="routerPath=='/farming'">Farming Pools</span>
    <span *ngIf="routerPath=='/staking'">Staking Pools</span>
    <span *ngIf="routerPath=='/pool-detail'">Pool Details</span>
    <span *ngIf="routerPath=='/portfolio'">Portfolio</span>
    <span *ngSwitchCase="'/login'">Login</span>
    <span *ngSwitchCase="'/create-pool'">Create Pool</span>
    <span *ngSwitchCase="'/claiming'">Claiming Deals</span>
    <span *ngSwitchCase="'/claiming-deal-detail'">Deal Details</span>
    <span *ngSwitchCase="'/vesting'">Claim Tokens</span>
  </div>

  <div class="mobile-logo">
    <a href="/"><img src="../../assets/images/logo-bull.svg" alt="Bull Starter"/></a>
  </div>
</header>
